import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inventories from "./pages/inventories";
import RequireAuth from "./components/require_auth";
import { connect } from "react-redux";
import { checkAuth } from "../src/redux/index";
import Login from "./pages/login";
import Signup from "./pages/signup";
import OrderDetail from "./pages/orderdetail";
import CreateInventory from "./pages/create_inventory";
import PasswordForgotten from "./pages/password_forgotten";
import ResetConfirmation from "./pages/resetConfirmation";
import HomeWithAuth from "./components/homeWithAuth";
import HomeWithoutAuth from "./components/homeWithoutAuth";
import SignupConfirmation from "./pages/signupConfirmation";
import ResetPassword from "./pages/password_reset";
import ResetPasswordSuccess from "./pages/reset_password_success";

function App() {
  // this.props.checkAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            // this.props.auth ? (
            //   <HomeWithAuth />
            // ) : (
            <HomeWithoutAuth></HomeWithoutAuth>
            // )
          }
        ></Route>
        <Route
          path="/auth/home"
          element={
            <RequireAuth>
              <HomeWithAuth />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home"
          element={<HomeWithoutAuth></HomeWithoutAuth>}
        ></Route>

        <Route
          path={"/inventories"}
          element={
            <RequireAuth>
              <Inventories />
            </RequireAuth>
          }
        />

        <Route path={"/login"} element={<Login />} />
        <Route path={"/resetConfirmation"} element={<ResetConfirmation />} />
        <Route
          path={"/resetPasswordSuccess"}
          element={<ResetPasswordSuccess />}
        />
        <Route path={"/resetPassword"} element={<ResetPassword />} />
        <Route path={"/signup"} element={<Signup />} />
        <Route path={"/signupConfirmation"} element={<SignupConfirmation />} />
        <Route
          path={"/inventories/details"}
          element={
            <RequireAuth>
              <OrderDetail />
            </RequireAuth>
          }
        />
        <Route
          path={"/inventories/arbitrate"}
          element={
            <RequireAuth>
              <OrderDetail />
            </RequireAuth>
          }
        />
        <Route
          path={"/inventories/create"}
          element={
            <RequireAuth>
              <CreateInventory />
            </RequireAuth>
          }
        />
        <Route
          path={"/inventories/update"}
          element={
            <RequireAuth>
              <CreateInventory />
            </RequireAuth>
          }
        />
        <Route path={"/forgotten_password"} element={<PasswordForgotten />} />

        {/* <Route path={"/account"} element=''/> */}
      </Routes>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { checkAuth })(App);
