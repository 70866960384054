import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { putPasswordReset } from "../redux/index";
import Navbarhub from "../components/navbarhub";
import { useNavigate } from "react-router-dom";
import "../app.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: false,
      passwordResetCreation: "",
      passwordResetConfirmation: "",
      passwordResetError: "",
      errorConfirmation: false,
      errorCreation: false,
    };
    this.handlePasswordCreationChange =
      this.handlePasswordCreationChange.bind(this);
    this.handlePasswordConfirmationChange =
      this.handlePasswordConfirmationChange.bind(this);
    this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);

    this.isPasswordValid = this.isPasswordValid.bind(this);
    this.resetError = this.resetError.bind(this);
  }

  componentDidUpdate() {}

  handlePasswordCreationChange(event) {
    this.setState({
      passwordResetCreation: event.target.value,
      passwordResetError: "",
    });
  }

  handlePasswordConfirmationChange(event) {
    this.setState({
      passwordResetConfirmation: event.target.value,
      passwordResetError: "",
    });
  }

  isPasswordValid(password) {
    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*)(+=._-]/;
    const hasHeight = /(?=.{8,})/;
    const hasMaj = /[A-Z]/;

    return (
      hasNumber.test(password) &&
      hasSymbol.test(password) &&
      hasHeight.test(password) &&
      hasMaj.test(password)
    );
  }

  handleSubmitResetPassword(event) {
    event.preventDefault();
    if (!this.state.passwordResetCreation) {
      this.setState({
        passwordResetError: "Mot de passe requis",
        errorCreation: true,
      });
    } else if (
      this.isPasswordValid(this.state.passwordResetCreation) !== true
    ) {
      this.setState({
        passwordResetError:
          "Le mot de passe doit contenir au moins 8 caractères dont une majuscule, un symbole et un chiffre",
        errorCreation: true,
      });
    } else if (!this.state.passwordResetConfirmation) {
      this.setState({
        passwordResetError: "Confirmation du mot de passe requise",
        errorConfirmation: true,
      });
    } else if (
      this.state.passwordResetConfirmation !== this.state.passwordResetCreation
    ) {
      this.setState({
        passwordResetError: "Les mots de passe ne sont pas les mêmes",
        errorCreation: true,
        errorConfirmation: true,
      });
    } else {
      console.log("ici");
      let password = {
        password: this.state.passwordResetCreation,
      };
      this.props.putPasswordReset(password);

      if (!this.props.passwordError) {
        this.props.navigate("/resetPasswordSuccess");
      }
    }
  }

  resetError() {
    this.setState({ errorCreation: false, errorConfirmation: false });
  }

  render() {
    return (
      <div>
        <Navbarhub />
        <div className="container">
          <div className="login" style={{ marginTop: "7rem" }}>
            <h2 className="text--h2">Réinitialisation du mot de passe</h2>
            <div className="login__form">
              <Form>
                <FormGroup>
                  <Label for="exampleEmail" className="font_grey font_light">
                    Nouveau mot de passe
                  </Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    type="password"
                    onChange={this.handlePasswordCreationChange}
                    onFocus={this.resetError}
                    style={
                      this.state.errorCreation !== false
                        ? { borderColor: "red" }
                        : {
                            backgroundColor: "rgb(244, 244, 244)",
                            border: "none",
                          }
                    }
                  />
                  <br></br>
                  <Label for="exampleEmail" className="font_grey font_light">
                    Confirmation du mot de passe
                  </Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    type="password"
                    onChange={this.handlePasswordConfirmationChange}
                    onFocus={this.resetError}
                    style={
                      this.state.errorConfirmation !== false
                        ? { borderColor: "red" }
                        : {
                            backgroundColor: "rgb(244, 244, 244)",
                            border: "none",
                          }
                    }
                  />
                </FormGroup>
              </Form>
              {this.state.passwordResetError && (
                <p className="error-message">{this.state.passwordResetError}</p>
              )}

              <Button
                onClick={this.handleSubmitResetPassword}
                color="primary"
                className="btn_color_orange"
                style={{
                  marginBottom: "15px",
                  border: "none",
                }}
              >
                Continuer
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Password_reset_Wrapper = (props) => {
  const navigate = useNavigate();

  return <ResetPassword {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => {
  return {
    passwordError: state.passwordError,
    navigate: state.navigate,
  };
};

export default connect(mapStateToProps, { putPasswordReset })(
  Password_reset_Wrapper
);
