import React from "react";
import { connect } from "react-redux";
import Navbarhub from "../components/navbarhub";

class Unauthorized extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Navbarhub />

        <div className="row pt-5">
          <div className="col-3"></div>
          <div className="col-6" style={{ marginTop: "4rem" }}>
            <h2>Connectez-vous pour accéder à ce contenu.</h2>
          </div>
          <div className="col-3"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Unauthorized);
