import React from "react";
import { connect } from "react-redux";
import TableauMui from "../components/tableauMui";
import Navbarhub from "../components/navbarhub";

class Inventories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: "Liste des inventaires",
    };
  }

  render() {
    return (
      <div>
        <Navbarhub />

        <div className="container" style={{ marginTop: "60px" }}>
          <div className="row">
            <TableauMui></TableauMui>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(Inventories);
