import React from "react";
import {
  getOrders,
  getOrderDetails,
  updateOrder,
  createOrder,
  checkAuth,
} from "../redux/index";
import Navbarhub from "./navbarhub";
import { connect } from "react-redux";
import TableauInvHome from "./tableauInvHome";
import { Paper, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

class HomeWithAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      orders: [],
    };

    this.toInventoryNumber = this.toInventoryNumber.bind(this);
    this.handleRowsCreation = this.handleRowsCreation.bind(this);
  }

  componentDidUpdate() {
    this.props.checkAuth();
    if (!!this.props.orders && this.state.orders.length === 0) {
      let orders = [...this.props.orders.inventories];
      let newRows = this.handleRowsCreation();

      this.setState({
        orders: orders,
        rows: newRows,
      });
    }
  }

  componentDidMount() {
    this.props.checkAuth();
    if (this.state.orders.length === 0) {
      this.props.getOrders();
    }
  }

  toInventoryNumber(tab) {
    let inProgress = 0;
    let toInventory = 0;
    tab.map((elem) => {
      if (elem.status === "to_inventory") {
        inProgress += 1;
      } else if (elem.status === "inventory_progress") {
        toInventory += 1;
      }
    });
    this.setState({ inProgress: inProgress, toInventory: toInventory });
  }

  /**
   * Création des lignes du tableau grâce au tableau des inventaires récupéré de la BDD
   */
  handleRowsCreation() {
    var newRows = [];

    if (this.props.orders && this.state.rows.length === 0) {
      this.props.orders.inventories.map((element) => {
        if (
          element.status === "to_inventory" ||
          element.status === "inventory_progress"
        ) {
          newRows = [
            ...newRows,
            {
              idName: element.order_name,
              idOrder: element.order_id,
              receptionDate: "20/03",
              status: element.status,
            },
          ];
        }
      });
      this.toInventoryNumber(newRows);
      this.setState({ rows: newRows });
    }
    return newRows;
  }

  render() {
    return (
      <div style={{ backgroundColor: "rgb(245,245,245)" }}>
        <Navbarhub forceAuth={!!this.props.loginInfo} />
        <div>
          <div className="raw " style={{ height: "100vh" }}>
            <div className="col-5">
              <div
                className="raw"
                style={{ height: "300px", paddingTop: "8rem" }}
              >
                <div className="col-1"></div>
                <div className="col-10">
                  <span
                    className="text--h2"
                    style={{
                      fontSize: "45px",
                      color: "#777776",
                      fontFamily: "Regular",
                      lineHeight: 1.5,
                    }}
                  >
                    Bienvenue sur votre espace {""}
                  </span>
                  <span
                    className="text--h2"
                    style={{
                      fontSize: "45px",
                      color: "rgb(199,83,67)",
                    }}
                  >
                    réception et inventaire
                  </span>
                </div>
                <div className="col-1"></div>
              </div>
              <div style={{ alignItems: "center" }}>
                {/* <img
                  alt="logo"
                  src={manutan_securite}
                  style={{
                    height: 600,
                    width: "auto",
                  }}
                /> */}
              </div>
            </div>
            <div
              className=" col-7"
              style={{
                alignItems: "center",
                backgroundColor: "white",
                padding: "3rem",
              }}
            >
              <div style={{ height: "3rem" }}></div>
              <div>
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid rgb(235,235,235)",
                    borderRadius: "30px",
                    height: "19rem",
                    width: "100%",
                    marginBottom: "2rem",
                  }}
                >
                  <div
                    style={{
                      padding: "2rem",
                    }}
                  >
                    <div className="raw">
                      <div>
                        <h2
                          style={{
                            fontSize: "20px",
                            color: "black",
                            paddingBottom: "1rem",
                          }}
                        >
                          Réceptions
                        </h2>
                      </div>
                      <div
                        className="raw"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "14px",
                            color: "black",
                            paddingBottom: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          Inventaires en cours : {""}
                          {""}
                        </h2>{" "}
                        <h2
                          style={{
                            fontSize: "14px",
                            color: "black",
                            paddingBottom: "1rem",
                          }}
                        >
                          A inventorier :
                        </h2>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "3rem" }}>
                      <TableauInvHome rows={this.state.rows}></TableauInvHome>
                    </div>
                    <Link href="#" underline="none">
                      <span>Aller aux réceptions</span>
                      <NavigateNextIcon></NavigateNextIcon>
                    </Link>
                  </div>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid rgb(235,235,235)",
                    borderRadius: "30px",
                    height: "19rem",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      padding: "2rem",
                    }}
                  >
                    <div className="raw">
                      <div>
                        <h2
                          style={{
                            fontSize: "20px",
                            color: "black",
                            paddingBottom: "1rem",
                          }}
                        >
                          Inventaires
                        </h2>
                      </div>
                      <div
                        className="raw"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "14px",
                            color: "black",
                            paddingBottom: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          Inventaires en cours : {this.state.inProgress} {""}
                          {""}
                        </h2>{" "}
                        <h2
                          style={{
                            fontSize: "14px",
                            color: "black",
                            paddingBottom: "1rem",
                          }}
                        >
                          A inventorier : {this.state.toInventory}
                        </h2>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "3rem" }}>
                      <TableauInvHome rows={this.state.rows}></TableauInvHome>
                    </div>
                    <Link href="/inventories" underline="none">
                      <span>Aller aux inventaires</span>
                      <NavigateNextIcon></NavigateNextIcon>
                    </Link>
                  </div>
                </Paper>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    orders: state.orders,
    orderDetails: state.orderDetails,
    loginInfo: state.loginInfo,
  };
}

export default connect(mapStateToProps, {
  createOrder,
  getOrders,
  getOrderDetails,
  updateOrder,
  checkAuth,
})(HomeWithAuth);
