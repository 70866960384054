import { homeBackend, homeBackendAuth, pricing } from "../http-common";
import { putPasswordReset } from "../redux";

class ApiService {
  getLoginInfo(loginInfo) {
    return homeBackend.post("/account/hubber/signin", loginInfo);
  }

  getEmailInfo(emailInfo) {
    return homeBackend.put("/account/hubber/forgotten_password", emailInfo);
  }

  sendRegistrationInfo(registrationInfo) {
    return homeBackend.post("/account/hubber/signup", registrationInfo);
  }

  getInventoryDetails(orderId) {
    return homeBackendAuth.post(
      `/hub/products/get_details_inventory/${orderId}`
    );
  }

  exportPdf(orderId) {
    return homeBackendAuth.post(
      `/hub/products/export_inventory_pdf/${orderId}`
    );
  }

  getOrders(last_id) {
    return homeBackendAuth.get(`/hub/products/all_inventories/${last_id}`);
  }

  getOrderDetails(order_id) {
    return homeBackendAuth.get(
      `/hub/products/get_details_inventory/${order_id}`
    );
  }

  getCheckAuth() {
    return homeBackendAuth.get("/account/hubber/checkAuth");
  }

  postSaveOrder(inventory) {
    return homeBackendAuth.post("/hub/products/save-inventory", inventory);
  }

  submitOrder(order) {
    return homeBackendAuth.post("hub/products/create-inventory", order);
  }

  getCategories() {
    return pricing.get("/api/v1/get_all_categories_brands");
  }

  getBrandPerCategory(category) {
    return pricing.get(`/api/v1/get_brands/${category}`);
  }

  getModelPerBrandAndCategory(brand, category) {
    return pricing.get(`/api/v1/get_devices/${brand}/${category}`);
  }

  putPasswordReset(password) {
    return homeBackend.put("/account/hubber/reset_password", password);
  }
}

export default new ApiService();
