import React from "react";
import Navbarhub from "../components/navbarhub";
import { connect } from "react-redux";
import { checkAuth } from "../redux";
import manutan_qualite from "../assets/Manutan_Qualite2.png";
import { Button, Link } from "@mui/material";

class HomeWithoutAnth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    return (
      <div style={{ backgroundColor: "rgb(245,245,245)" }}>
        <Navbarhub forceAuth={!!this.props.loginInfo} />
        <div>
          <div className="raw " style={{ height: "100vh" }}>
            <div className="col-1"></div>
            <div className="col-10" style={{ paddingTop: "7rem" }}>
              <div>
                <span
                  className="text--h2"
                  style={{
                    fontSize: "45px",
                    color: "#777776",
                    fontFamily: "Regular",
                    lineHeight: 1.5,
                  }}
                >
                  Bienvenue sur l'espace partenaire de {""}
                </span>
              </div>
              <div style={{ marginLeft: "5rem" }}>
                <span
                  className="text--h2"
                  style={{
                    fontSize: "45px",
                    color: "rgb(199,83,67)",
                  }}
                >
                  Manutan Collecte et Revalorisation
                </span>
              </div>
              <div className="raw">
                <div
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "5rem",
                  }}
                >
                  {/* <Link href="/login">
                    <Button
                      variant="outlined"
                      style={{
                        height: "40px",
                        borderColor: "rgb(89,89,89)",
                        color: "rgb(89,89,89)",
                        borderRadius: "20px",
                        fontFamily: "SemiBold",
                        fontStyle: "normal",
                        width: "140px",
                        borderWidth: "3px",
                      }}
                    >
                      Se connecter
                    </Button>
                  </Link> */}
                </div>
                <div
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    alt="logo"
                    src={manutan_qualite}
                    style={{
                      height: 500,
                      width: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-1"></div>

            <div style={{ height: "3rem" }}></div>

            <div className="col-1"></div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  checkAuth,
})(HomeWithoutAnth);
