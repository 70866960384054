import React from "react";
import { checkAuth } from "../redux/index";
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

class tableauInvHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      rows: [],
    };

    this.customStatus = this.customStatus.bind(this);
  }

  componentDidMount() {
    // if (this.state.orders.length === 0) {
    //   this.props.getOrders();
    // }
  }

  componentDidUpdate() {
    // if (!!this.props.orders && this.state.orders.length === 0) {
    //   let orders = [...this.props.orders.inventories];
    //   let newRows = this.handleRowsCreation();
    //   this.setState({
    //     orders: orders,
    //     rows: newRows,
    //   });
    // }
  }

  /**
   * Création des lignes du tableau grâce au tableau des inventaires récupéré de la BDD
   */
  //   handleRowsCreation() {
  //     var newRows = [];

  //     if (this.props.orders && this.state.rows.length === 0) {
  //       this.props.orders.inventories.map((element) => {
  //         if (
  //           element.status === "to_inventory" ||
  //           element.status === "inventory_progress"
  //         ) {
  //           newRows = [
  //             ...newRows,
  //             {
  //               idName: element.order_name,
  //               idOrder: element.order_id,
  //               receptionDate: "20/03",
  //               status: element.status,
  //             },
  //           ];
  //         }
  //       });
  //       return newRows;
  //     }
  //     console.log("vaslà");
  //     this.props.toInventoryNumber(newRows);
  //     this.setState({ rows: newRows });
  //   }

  customStatus(element) {
    if (element.value === "inventory_progress") {
      return (
        <Chip
          label=" inventaire en cours"
          variant="outlined"
          sx={{
            backgroundColor: "#DCDFF4",
            color: "#5969CC",
            borderColor: "#5969CC",
            width: "100px",
            fontSize: "10px",
          }}
        />
      );
    } else if (element.value === "to_inventory") {
      return (
        <Chip
          label="à inventorier"
          variant="outlined"
          sx={{
            backgroundColor: "#E3F5EB",
            color: "green",
            borderColor: "#85D3A9",
            width: "100px",
          }}
        />
      );
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontFamily: "Regular",
                    color: "black",
                  },
                }}
              >
                <TableCell>Nom du lot</TableCell>
                <TableCell align="right">Id Order</TableCell>
                <TableCell align="right">Date de réception</TableCell>
                <TableCell align="center">Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows
                ? this.props.rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td": {
                          fontFamily: "Regular",
                        },
                      }}
                    >
                      <TableCell align="left">{row.idName}</TableCell>
                      <TableCell align="right">{row.idOrder}</TableCell>
                      <TableCell align="right">{row.receptionDate}</TableCell>
                      <TableCell align="center">
                        <this.customStatus value={row.status} />
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { checkAuth })(tableauInvHome);
