import React from "react";
import { connect } from "react-redux";
import {
  getOrders,
  getOrderDetails,
  updateOrder,
  createOrder,
} from "../redux/index";
import { Link } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { IconButton, Tooltip, Box } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";

class Tableau extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      columns: [
        {
          field: "seller_id",
          headerName: "ID Client",
          width: 180,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "idName",
          headerName: "Nom du lot",
          width: 180,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "idOrder",
          headerName: "ID Order",
          width: 180,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "receptionDate",
          headerName: "Date de réception",
          width: 180,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "status",
          headerName: "Statut",
          width: 180,
          headerClassName: "super-app-theme--header",
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            return (
              <div className="d-flex justify-content-between align-items-center">
                <this.customStatus index={params.row} />
              </div>
            );
          },
        },
        {
          field: "emoticon",
          headerName: "",
          headerClassName: "super-app-theme--header",
          width: 120,
          renderCell: (params) => {
            console.log(params);
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                {this.getEmoticon(params.row)}
              </div>
            );
          },
        },
      ],
    };

    this.handleClickDetails = this.handleClickDetails.bind(this);
    this.handleClickUpdate = this.handleClickUpdate.bind(this);
    this.handleRowsCreation = this.handleRowsCreation.bind(this);
    this.handleClickCreation = this.handleClickCreation.bind(this);
    this.getEmoticon = this.getEmoticon.bind(this);
    this.CustomPagination = this.CustomPagination.bind(this);
    this.customStatus = this.customStatus.bind(this);
    
  }

  componentDidMount() {
    if (this.state.orders.length === 0) {
      this.props.getOrders();
    }
  }

  componentDidUpdate() {
    if (!!this.props.orders && this.state.orders.length === 0) {
      let orders = [...this.props.orders.inventories];
      let newRows = this.handleRowsCreation();
      this.setState({
        orders: orders,
        rows: newRows,
      });
    }
  }

  /**
   * @param {*} e evenement où a été stockée l'order id
   * La fonction récupère l'order id de la ligne séléctionnée et l'envoie dans le store dans la méthode getOrderDetail (avoir le détail d'un inventaire)
   */
  handleClickDetails(e) {
    let order_id = e.target.parentElement.value;
    if (!order_id) {
      order_id = e.target.parentElement.parentElement.value;
    }
    this.props.getOrderDetails(order_id);
  }

  /**
   * @param {*} e evenement où a été stockée l'order id
   * La fonction récupère l'order id de la ligne séléctionnée et l'envoie dans le store dans la méthode updateOrder (modifier l'inventaire)
   */
  handleClickUpdate(e) {
    let order_id = e.target.parentElement.value;
    if (!order_id) {
      order_id = e.target.parentElement.parentElement.value;
    }
    let order = this.state.orders.find((o, i) => o.order_id === order_id);

    this.props.updateOrder(order);
  }

  /**
   * @param {*} e evenement où a été stockée toutes les infos de l'inventaire séléctionné
   * La fonction récupère les infos de la ligne séléctionnée et l'envoie dans le store dans la méthode createOrder (créer un inventaire)
   */
  handleClickCreation(e) {
    let order_id = e.target.parentElement.value;
    if (!order_id) {
      order_id = e.target.parentElement.parentElement.value;
    }
    let order = this.state.orders.find((o, i) => o.order_id === order_id);

    this.props.createOrder(order);
  }

  CustomPagination(props) {
    const pagination = props;
    const getLabel = () => {
      const { rowCount, page, pageCount } = pagination;

      if (rowCount === 0) {
        return "Aucun élément";
      } else {
        return `Page ${page + 1} sur ${pageCount}`;
      }
    };
  }

  /**
   * @param {*} element objet comprenant toutes les informations de l'inventaire séléctionné
   * En fonction du statut de l'inventaire séléctionné, la fonction va afficher l'icon approprié (loupe pour une vue de l'inventaire, crayon pour modifier l'inventaire, start pour démarrer l'inventaire)
   */
  getEmoticon(element) {
    if (element.status === "to_inventory") {
      return (
        <div className="col">
          <Link to={"/inventories/create"}>
            <Tooltip title={"Creation"}>
              <IconButton
                color="primary"
                value={element.idOrder}
                onClick={this.handleClickCreation}
              >
                <NotStartedIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      );
    } else if (element.status === "inventory_progress") {
      return (
        <div className="col">
          <Link to={"/inventories/update"}>
            <Tooltip title="Modifier">
              <IconButton
                color="primary"
                value={element.idOrder}
                onClick={this.handleClickUpdate}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      );
    } else if (element.status === "pending") {
      return (
        <div className="col">
          <Link to={"/inventories/details"}>
            <Tooltip title={"Détails"}>
              <IconButton
                color="primary"
                value={element.idOrder}
                onClick={this.handleClickDetails}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      );
    } else if (element.status === "done") {
      return (
        <div className="col">
          <Link to={"/inventories/details"}>
            <Tooltip title={"Détails"}>
              <IconButton
                color="primary"
                value={element.idOrder}
                onClick={this.handleClickDetails}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      );
    } else if (element.status === "to_arbitrate") {
      return (
        <div className="col">
          <Link to={"/inventories/arbitrate"}>
            <Tooltip title={"Arbitrage"}>
              <IconButton
                color="primary"
                value={element.idOrder}
                onClick={this.handleClickDetails}
              >
                <AltRouteIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      );
    }
  }

  /**
   * Création des lignes du tableau grâce au tableau des inventaires récupéré de la BDD
   */
  handleRowsCreation() {
    var newRows = [];
    if (this.props.orders && this.state.rows.length === 0) {
      this.props.orders.inventories.map((element, index) => {
        newRows = [
          ...newRows,
          {
            id: index,
            seller_id: element.seller_id,
            idName: element.order_name,
            idOrder: element.order_id,
            receptionDate: "20/03",
            status: element.status,
            emoticon: this.getEmoticon(element),
          },
        ];
      });
      return newRows;
    }
  }

  customStatus(element) {
    if (element.index.status === "pending") {
      return (
        <Chip
          label="pending"
          variant="outlined"
          sx={{
            backgroundColor: "#F3DDF3",
            color: "#C462C4",
            borderColor: "#C462C4",
            width: "100px",
          }}
        />
      );
    } else if (element.index.status === "inventory_progress") {
      return (
        <Chip
          label=" inventaire en cours"
          variant="outlined"
          sx={{
            backgroundColor: "#FCA390",
            color: "#5969CC",
            borderColor: "#5969CC",
            width: "100px",
            fontSize: "10px",
          }}
        />
      );
    } else if (element.index.status === "to_inventory") {
      return (
        <Chip
          label="à inventorier"
          variant="outlined"
          sx={{
            backgroundColor: "#E3F5EB",
            color: "green",
            borderColor: "#85D3A9",
            width: "100px",
          }}
        />
      );
    } else if (element.index.status === "done") {
      return (
        <Chip
          label="Terminer"
          variant="outlined"
          sx={{
            backgroundColor: "#FF8368",
            color: "red",
            borderColor: "#FD2E01",
            width: "100px",
          }}
        />
      );
    } else if (element.index.status === "to_arbitrate") {
      return (
        <Chip
          label="A arbitrer"
          variant="outlined"
          sx={{
            backgroundColor: "#FCFE9F",
            color: "black",
            borderColor: "#C8CD00",
            width: "100px",
          }}
        />
      );
    }
  }

  render() {
    return (
      <div className="row">
        <div className="row">
          <div className="col-3">
            <h2
              className="text--h2"
              style={{ marginTop: "15px", marginBottom: "25px" }}
            >
              Liste des inventaires
            </h2>
          </div>
        </div>
        <div>
          <div className="row pb-3">
            <div className="col"></div>
            <div className="col"></div>
          </div>
          <div className="row pt-2">
            <div className="col"></div>
            <div className="col-10">
              <div style={{ height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    height: 550,
                    width: "auto",
                    border: "none",
                    "& .super-app-theme--header": {
                      fontFamily: "Regular",
                    },
                  }}
                >
                  <DataGrid
                    rows={this.state.rows}
                    columns={this.state.columns}
                    pageSize={10}
                    localeText={
                      frFR.components.MuiDataGrid.defaultProps.localeText
                    }
                    components={{
                      Pagination: this.CustomPagination,
                    }}
                    rowsPerPageOptions={[5]}
                    sx={{
                      "& .MuiDataGrid-root": {
                        width: "auto",
                      },
                      "& .MuiTablePagination-caption": {
                        fontFamily: "Regular",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        fontFamily: "Regular",
                        color: "rgba(96, 96, 96)",
                        borderTop: 1,
                        borderBottom: 1,
                        borderColor: "black",
                      },
                      "& .MuiTablePagination-text": {
                        fontFamily: "Regular",
                      },
                      "& .MuiTablePagination-selectLabel": {
                        fontFamily: "Regular",
                      },
                      "& .MuiTablePagination-select": {
                        fontFamily: "Regular",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        fontFamily: "Regular",
                      },
                      ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                        {
                          "margin-top": "1em",
                          "margin-bottom": "1em",
                        },
                      p: 2,
                      pb: 0,
                    }}
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                  />
                </Box>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    orders: state.orders,
    orderDetails: state.orderDetails,
  };
}

export default connect(mapStateToProps, {
  createOrder,
  getOrders,
  getOrderDetails,
  updateOrder,
})(Tableau);
