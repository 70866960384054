import React from "react";
import Navbarhub from "../components/navbarhub";
import manutan_femme_bureau from "../assets/femme_bureau_ss_pdt.png";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ backgroundColor: "rgb(245,245,245)" }}>
        <Navbarhub />
        <div>
          <div className="raw " style={{ height: "100vh" }}>
            <div className="col-1"></div>
            <div className="raw col-10" style={{ paddingTop: "5rem" }}>
              <div className="col-6" style={{ paddingTop: "10rem" }}>
                <span
                  className="text--h2"
                  style={{
                    fontSize: "18px",
                    color: "#777776",
                    fontFamily: "Regular",
                    lineHeight: 1.5,
                  }}
                >
                  Nous sommes ravis de vous informer que votre demande de
                  réinitialisation de mot de passe a bien été prise en compte.
                  Un courriel de confirmation vous a été envoyé à l'adresse
                  e-mail fournie lors de votre demande. Dans ce courriel, vous
                  trouverez un lien sécurisé qui vous permettra de réinitialiser
                  votre mot de passe.
                  <br></br> <br></br>
                  Cordialement, <br></br> <br></br>[L'équipe support]
                </span>
              </div>
              <div className="col-5">
                <img
                  alt="logo"
                  src={manutan_femme_bureau}
                  style={{
                    height: 700,
                    width: "auto",
                  }}
                />
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
