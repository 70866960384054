import React from "react";
import "../app.css";
import { connect } from "react-redux";
import { exportPdf } from "../redux/index";
import MainTable from "../components/maintable";
import { Button, Modal, Box, Stack } from "@mui/material";
import { Navbar } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import Navbarhub from "../components/navbarhub";

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: "Wedia-20-03-2021",
      inventoryDetailsFromBack: {},
      recycleCounter: 0,
      giveCounter: 0,
      sellCounter: 0,
      columns: [
        {
          width: "300px",
          label: "Catégories",
          dataKey: "category",
        },
        {
          width: "300px",
          label: "Marque",
          dataKey: "brand",
        },
        {
          width: "300px",
          label: "SKU",
          dataKey: "sku",
        },
        {
          width: "300px",
          label: "Modèle",
          dataKey: "model",
        },
        {
          width: "300px",
          label: "Consigne",
          dataKey: "wanted_option",
        },
      ],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModal = this.handleArbitration.bind(this);
    this.handleRemarks = this.handleRemarks.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    if (!!this.props.orderDetails) {
      let { state } = useLocation();
      this.props.getOrderDetails(state.order_id);
      console.log(this.props.orderDetails, "orderdetail");
    }
    console.log(this.props.orderDetails, "orderdetail");
  }

  componentDidUpdate() {
    console.log(this.props.orderDetails, "orderdetail");
    // if (!!this.props.exportPdf) {
    //   this.handleModal();
    // }
  }

  handleClick() {
    // this.props.exportPdf(orderId);
  }

  handleModal() {
    this.setState({ open: !this.state.open });
  }

  handleArbitration(obj) {
    // if (this.props.orderDetails) {
    //   this.props.orderDetails.details.map((elem) => {
    //     if (obj.sku === elem.sku) {
    //       console.log("ici");
    //       elem.wanted_option = obj.wantedOption;
    //     }
    //   });
    // }
    console.log("e");
  }

  handleRemarks(obj) {
    // if (this.props.orderDetails) {
    //   this.props.orderDetails.details.map((elem) => {
    //     if (obj.sku === elem.sku) {
    //       elem[remarks] = obj.remarks;
    //     }
    //   });
    // }
    console.log("e");
  }

  onClickSubmit() {
    let newOrderDetails = { ...this.state.orderDetails };

    newOrderDetails.details.map((elem) => {
      if (elem.wantedOption === "") {
        elem.error = true;
      }
    });
    //envoyer données
    this.setState({ orderDetails: newOrderDetails });
  }

  optionCounter() {
    let sellCounter = 0;
    let giveCounter = 0;
    let recycleCounter = 0;
    this.props.orderDetails.details.map((elem) => {
      if (elem.wantedOption === "sell") {
        sellCounter += 1;
      } else if (elem.wantedOption === "give") {
        giveCounter += 1;
      } else if (elem.wantedOption === "recycle") {
        recycleCounter += 1;
      }
    });
    this.setState({
      sellCounter: sellCounter,
      giveCounter: giveCounter,
      recycleCounter: recycleCounter,
    });
  }

  render() {
    return (
      <div>
        <Navbarhub />

        <div className="container" style={{ marginTop: "60px" }}>
          <div>
            <div
              className="raw"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              {!!this.props.orderDetails ? (
                <h2
                  className="text--h2"
                  style={{ marginTop: "15px", marginBottom: "25px" }}
                >
                  {this.props.orderDetails.lot_name}
                </h2>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                onClick={this.handleClick}
                className="btn_transparent"
                style={{
                  borderWidth: "5px",
                  borderColor: "black",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                  fontWeight: "300",
                  borderRadius: "20px",
                  fontFamily: "SemiBold",
                  fontStyle: "normal",
                  color: "black",
                  height: "40px",
                }}
              >
                Exporter en PDF
              </Button>
              <Modal
                open={this.state.open}
                onClose={this.handleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="style">
                  <p>Text in a modal</p>
                  <p>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </p>
                </Box>
              </Modal>
            </div>
            {!!this.props.orderDetails ? (
              <div style={{ paddingTop: "30px" }}>
                <div className="tds-text--center">
                  <p className="mr">
                    Produits à vendre :{this.state.sellCounter}
                  </p>
                  <p className="mr">
                    Produits à donner :{this.state.giveCounter}{" "}
                  </p>
                  <p className="mr">
                    Produits à recycler :{this.state.recycleCounter}{" "}
                  </p>
                </div>

                <MainTable
                  inventoryDetails={this.props.orderDetails.details}
                  status={this.props.orderDetails.status}
                  columns={this.state.columns}
                  handleArbitration={this.handleArbitration}
                  handleRemarks={this.handleRemarks}
                ></MainTable>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <footer className="row">
          <Navbar fixed="bottom" style={{ paddingBottom: "25px" }}>
            <div className="col-12">
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <Stack
                  spacing={2}
                  direction="row"
                  elevation={0}
                  width="auto"
                  paddingRight="16px"
                >
                  <Link to={"/inventories"}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{
                        width: "180px",
                        fontSize: "12px",
                        padding: "8px 8px",
                        fontFamily: "RobotoRegular",
                      }}
                      onClick={this.onClickSubmit}
                    >
                      Terminer l'arbitrage
                    </Button>
                  </Link>
                </Stack>
              </div>
            </div>
          </Navbar>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderDetails: state.orderDetails,
    exportPdf: state.exportPdf,
  };
};

export default connect(mapStateToProps, {
  exportPdf,
})(OrderDetail);
