import {
  CHECK_AUTH,
  GET_LOGIN_INFO,
  POST_REGISTRATION_INFO,
  GET_INVENTORY_DETAILS,
  EXPORT_PDF,
  GET_LOGIN_ERROR,
  GET_ORDERS,
  GET_ORDER_DETAILS,
  SAVE_ORDER,
  UPDATE_ORDER,
  CREATE_ORDER,
  SUBMIT_ORDER,
  GET_CATEGORIES,
  GET_BRAND,
  GET_MODEL,
  GET_EMAIL_INFO,
  GET_EMAIL_ERROR,
  SEND_REGISTRATION_ERROR,
  PUT_PASSWORD_ERROR,
  PUT_PASSWORD_RESET,
} from "./types";

export default function (state = {}, action) {
  switch (action.type) {
    case CHECK_AUTH:
      return { ...state, auth: action.payload };
    case GET_LOGIN_INFO:
      return { ...state, loginInfo: action.payload };
    case GET_EMAIL_INFO:
      return { ...state, emailInfo: action.payload };
    case POST_REGISTRATION_INFO:
      return { ...state, registrationInfo: action.payload };
    case GET_INVENTORY_DETAILS:
      return { ...state, inventoryDetails: action.payload };
    case EXPORT_PDF:
      return { ...state, exportPdf: action.payload };
    case GET_LOGIN_ERROR:
      return { ...state, loginInfoError: action.error };
    case GET_EMAIL_ERROR:
      return { ...state, emailInfoError: action.error };
    case GET_ORDERS:
      return { ...state, orders: action.payload };
    case GET_ORDER_DETAILS:
      return { ...state, orderDetails: action.payload };
    case SAVE_ORDER:
      return { ...state, orderSaved: action.payload };
    case UPDATE_ORDER:
      return { ...state, orderUpdate: action.payload };
    case CREATE_ORDER:
      return { ...state, createOrder: action.payload };
    case SUBMIT_ORDER:
      return { ...state, submitOrder: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_BRAND:
      return { ...state, brands: action.payload };
    case GET_MODEL:
      return { ...state, models: action.payload };
    case SEND_REGISTRATION_ERROR:
      return { ...state, sendRegistrationError: action.payload };
    case PUT_PASSWORD_RESET:
      return { ...state, password: action.payload };
    case PUT_PASSWORD_ERROR:
      return { ...state, passwordError: action.error };
    default:
      return {};
  }
}
