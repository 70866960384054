import React from "react";
import Navbarhub from "../components/navbarhub";
import femme from "../assets/Manutan_Epanouissement_bleu.png";

class SignupConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ backgroundColor: "rgb(245,245,245)" }}>
        <Navbarhub />
        <div>
          <div className="raw " style={{ height: "100vh" }}>
            <div className="col-1"></div>
            <div className="raw col-10" style={{ paddingTop: "5rem" }}>
              <div className="col-5" style={{ paddingTop: "10rem" }}>
                <span
                  className="text--h2"
                  style={{
                    fontSize: "30px",
                    color: "#777776",
                    fontFamily: "Regular",
                    lineHeight: 1.5,
                  }}
                >
                  Félicitation, votre compte a bien été créé !
                </span>
              </div>
              <div className="col-7">
                <img
                  alt="logo"
                  src={femme}
                  style={{
                    height: 700,
                    width: "auto",
                  }}
                />
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupConfirmation;
