import React from "react";
import { TextField, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

class LigneHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.deleteHeaderLine = this.deleteHeaderLine.bind(this);
  }

  handleChangeName(e) {
    let operateurName = e.target.value;
    let id = this.props.id;

    this.props.handleChangeName({
      id: id,
      name: operateurName,
    });
  }

  handleChangeStartTime(e) {
    let startTime = e.target.value;

    let startTimeAbs = e.target.valueAsNumber;
    let id = this.props.id;

    this.props.handleChangeStartTime({
      id: id,
      startTime: startTime,
      startTimeAbs: startTimeAbs,
    });
  }

  handleChangeEndTime(e) {
    let endTime = e.target.value;
    let id = this.props.id;
    let endTimeAbs = e.target.valueAsNumber;

    this.props.handleChangeEndTime({
      id: id,
      endTime: endTime,
      endTimeAbs: endTimeAbs,
    });
  }

  deleteHeaderLine() {
    let id = this.props.id;
    this.props.deleteHeaderLine({ id: id });
  }

  render() {
    return (
      <TableRow id={this.props.id}>
        <TableCell>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={this.props.elem.name}
            onChange={this.handleChangeName}
            error={
              this.props.elem.name === "" ? this.props.elem.invNameError : false
            }
            sx={{
              fontFamily: "Regular",
              "& input": {
                height: 10,
                fontSize: "13px",
                width: "110px",
                padding: 1,
              },
            }}
          />
        </TableCell>

        <TableCell>
          <TextField
            error={
              this.props.elem.startTime === ""
                ? this.props.elem.startTimeError
                : false
            }
            type="time"
            value={this.props.elem.startTime}
            onChange={this.handleChangeStartTime}
            InputProps={{
              // inputProps: {
              //   step: 300, // 5 minutes interval
              // },
              sx: {
                "& input": {
                  height: 10,
                  fontSize: "13px",
                  width: "80px",
                  padding: 1,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            error={
              this.props.elem.endTime === ""
                ? this.props.elem.endTimeError
                : false
            }
            type="time"
            value={this.props.elem.endTime}
            onChange={this.handleChangeEndTime}
            InputProps={{
              sx: {
                "& input": {
                  height: 10,
                  fontSize: "13px",
                  width: "80px",
                  padding: 1,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            value={this.props.elem.totalPerOpTime}
            disabled
            InputProps={{
              // inputProps: {
              //   step: 300, // 5 minutes interval
              // },
              sx: {
                "& input": {
                  height: 10,
                  fontSize: "13px",
                  width: "80px",
                  padding: 1,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ padding: 0, height: "10px" }}
            onMouseOver={(event) =>
              (event.currentTarget.style.backgroundColor = "#F8FBFF")
            }
            onMouseLeave={(event) =>
              (event.currentTarget.style.backgroundColor = "")
            }
            onClick={this.deleteHeaderLine}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default LigneHeader;
