import axios from "axios";
import config from "./config/endpoints.json";
const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || "development";
const baseUrl = config[env].baseAPI;
const basePricing = config[env].basePricing;


const homeBackend = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
});

const homeBackendAuth = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: window.sessionStorage.getItem("hubToken"),
  },
});

const pricing = axios.create({
  baseURL: basePricing,
  headers: { "Content-Type": "application/json" },
});

export { homeBackend, homeBackendAuth, pricing };
