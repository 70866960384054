export const GET_AUTH_ERROR = "get_auth_error";
export const CHECK_AUTH = "check_auth";
export const GET_LOGIN_INFO = "get_login_info";
export const POST_REGISTRATION_INFO = "post_registration_info";
export const GET_INVENTORY_DETAILS = "get_inventory_details";
export const EXPORT_PDF = "export_pdf";
export const GET_LOGIN_ERROR = "get_login_error";
export const GET_ORDERS = "get_orders";
export const GET_ORDER_DETAILS = "get_order_details";
export const SAVE_ORDER = "save_order";
export const UPDATE_ORDER = "update_order";
export const CREATE_ORDER = "create_order";
export const SUBMIT_ORDER = "submit_order";
export const GET_CATEGORIES = "get_categories";
export const GET_BRAND = "get_brand";
export const GET_MODEL = "get_model";
export const GET_EMAIL_INFO = "get_email_info";
export const GET_EMAIL_ERROR = "get_email_error";
export const SEND_REGISTRATION_ERROR = "send_registration_error";
export const PUT_PASSWORD_ERROR = "put_password_error";
export const PUT_PASSWORD_RESET = "put_password_reset";
