import React from "react";
import { connect } from "react-redux";
import { checkAuth } from "../redux";
import { Navigate, useLocation } from "react-router-dom";
import Unauthorized from "../pages/unauthorized";

class RequireAuth extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.checkAuth();
  }

  componentDidUpdate() {}

  render() {
    return this.props.auth ? this.props.children : <Unauthorized />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { checkAuth })(RequireAuth);
