import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import LigneInventaire from "../components/ligne_inventaire";
import Navbarhub from "../components/navbarhub";
import { saveOrder, submitOrder, getCategories, getBrands, getModels} from "../redux";
import Header_create_inventories from "../components/header_create_inventories";
import { Navbar } from "reactstrap";
import Button from "@mui/material/Button";
import _ from 'underscore';
import Alert from "@mui/material/Alert";
import password from "../config/env.json";

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";

class CreateInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      navigation: false,
      errorOpen: false,
      open: false,
      orders: {
        order_id: "",
        name: "",
        client_id: "",
        products: [],
        totalInvTime: "",
        totalInvTimeAbs: 0,
        totalWeight: 0,
        totalQuantity: 0,
        invResponsable: "",
        invResponsableError: false,
        tableRow: [
          {
            name: "",
            startTime: "",
            startTimeAbs: 0,
            endTime: "",
            endTimeAbs: 0,
            totalPerOpTime: 0,
            totalPerOpTimeAbs: 0,
            invNameError: false,
            startTimeError: false,
            endTimeError: false,
          },
        ],
      },
    };
    this.partner = window.sessionStorage.getItem("partner");

    this.onChangeCategorie = this.onChangeCategorie.bind(this);
    this.onChangeQuantite = this.onChangeQuantite.bind(this);
    this.onClickAddButton = this.onClickAddButton.bind(this);
    this.handleClickSaveOrder = this.handleClickSaveOrder.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.handleRespName = this.handleRespName.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeScreenSize = this.onChangeScreenSize.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeFailure = this.onChangeFailure.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
    this.onChangeImei = this.onChangeImei.bind(this);
    this.deleteLine = this.deleteLine.bind(this);
    this.duplicateLine = this.duplicateLine.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickAddHeaderButton = this.onClickAddHeaderButton.bind(this);
    this.finalSubmit = this.finalSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateErrorHasChange = this.updateErrorHasChange.bind(this);
    this.deleteHeaderLine = this.deleteHeaderLine.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  handleChangeName(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.tableRow.map((elem, index) => {
      if (obj.id === index) {
        elem.name = obj.name;
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  handleChangeStartTime(obj) {
    let newOrders = { ...this.state.orders };
    let newInvTimeAbs = 0;
    let newInvTime = 0;

    newOrders.tableRow.map((elem, index) => {
      if (obj.id === index) {
        elem.startTime = obj.startTime;
        elem.startTimeAbs = obj.startTimeAbs;

        if (elem.endTime !== "" && elem.startTime !== "") {
          elem.totalPerOpTime = `${Math.floor(
            (elem.endTimeAbs - elem.startTimeAbs) / 3600000
          )} h ${
            Math.floor((elem.endTimeAbs - elem.startTimeAbs) / 60000) % 60
          } min`;

          elem.totalPerOpTimeAbs = elem.endTimeAbs - elem.startTimeAbs;
        }
      }
      return { elem, newInvTimeAbs };
    });

    for (let i = 0; i < newOrders.tableRow.length; i++) {
      if (newOrders.tableRow[i].totalPerOpTime !== 0) {
        newInvTimeAbs += newOrders.tableRow[i].totalPerOpTimeAbs;
      }
    }

    newInvTime = `${Math.floor(newInvTimeAbs / 3600000)} h ${
      (newInvTimeAbs / 60000) % 60
    } min`;

    newOrders.totalInvTime = newInvTime;
    newOrders.totalInvTimeAbs = newInvTimeAbs;

    this.setState({ orders: newOrders });
  }

  handleChangeEndTime(obj) {
    let newOrders = { ...this.state.orders };
    let newInvTimeAbs = 0;
    let newInvTime = 0;

    newOrders.tableRow.map((elem, index) => {
      if (obj.id === index) {
        elem.endTime = obj.endTime;
        elem.endTimeAbs = obj.endTimeAbs;

        if (elem.startTime !== "" && elem.endTime !== "") {
          elem.totalPerOpTime = `${Math.floor(
            (elem.endTimeAbs - elem.startTimeAbs) / 3600000
          )} h ${
            Math.floor((elem.endTimeAbs - elem.startTimeAbs) / 60000) % 60
          } min`;

          elem.totalPerOpTimeAbs = elem.endTimeAbs - elem.startTimeAbs;
        }
      }
      return { elem, newInvTimeAbs };
    });

    for (let i = 0; i < newOrders.tableRow.length; i++) {
      if (newOrders.tableRow[i].totalPerOpTime !== 0) {
        newInvTimeAbs += newOrders.tableRow[i].totalPerOpTimeAbs;
      }
    }

    newInvTime = `${Math.floor(newInvTimeAbs / 3600000)} h ${
      (newInvTimeAbs / 60000) % 60
    } min`;

    newOrders.totalInvTime = newInvTime;
    newOrders.totalInvTimeAbs = newInvTimeAbs;
    this.setState({ orders: newOrders });
  }

  handleRespName(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.invResponsable = obj.invResponsable;
    this.setState({ orders: newOrders });
  }

  onChangeClient(e) {
    this.setState({ client_id: e.target.value });
  }

  onChangeCategorie(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.categorie = obj.categorie.category;
        elem.gestionRule = obj.gestionRule;
        elem.catNumber = obj.catNumber;

        if(elem.catError){
          elem.catError = !elem.catError;
        }
      }
      return elem;
    });

    let brands = [];

    this.props.categories.forEach((cat)=>{
      if(cat.category.id === obj.categorie.category.id){
        brands = cat.brands;
      }
    })

    this.props.getBrands(brands);

    this.setState({ orders: newOrders });
  }

  onChangeQuantite(obj) {
    let newOrders = { ...this.state.orders };
    let totalQuantity = 0;
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.quantity = obj.quantity;
        if(elem.error.quantity){
          elem.error.quantity = !elem.error.quantity;
          elem.errorHasChange = true;
        }
      }
      if (!elem.disappear) {
        totalQuantity += parseInt(elem.quantity);
      }
    });
    newOrders.totalQuantity = totalQuantity;
    this.setState({ orders: newOrders });
  }

  onChangeWeight(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.weight = obj.weight;
        if(elem.error.weight){
          elem.error.weight = !elem.error.weight;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeBrand(obj) {
    let newOrders = { ...this.state.orders };

    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.brand = obj.brand.brand;
        if(elem.error.brand){
          elem.error.brand = !elem.error.brand;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });

    this.props.getModels(obj.brand.devices);

    this.setState({ orders: newOrders });
  }

  onChangeState(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.state = obj.state;
        if(elem.error.state){
          elem.error.state = !elem.error.state;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeScreenSize(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.screenSize = obj.screenSize;
        if(elem.error.screenSize){
          elem.error.screenSize = !elem.error.screenSize;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeModel(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.model = obj.model;
        if(elem.error.model){
          elem.error.model = !elem.error.model;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeFailure(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.failure = obj.failure;
        if(elem.error.failure){
          elem.error.failure = !elem.error.failure;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeRemarks(obj) {
    let newOrders = { ...this.state.orders };
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.remarks = obj.remarks;
        if(elem.error.remarks){
          elem.error.remarks = !elem.error.remarks;
          elem.errorHasChange = true;
        }
      }
      return elem;
    });
    this.setState({ orders: newOrders });
  }

  onChangeImei(obj) {
    let newOrders = { ...this.state.orders };

    for(let i = 0 ; i < newOrders.products.length ; i++){
      if (obj.id === newOrders.products[i].id) {
        newOrders.products[i].imei = obj.imei;
        if(newOrders.products[i].error.imei){
          newOrders.products[i].error.imei = !newOrders.products[i].error.imei;
          elem.errorHasChange = true;
        }
        break;
      }
    }

    this.setState({ orders: newOrders });
  }

  deleteLine(obj) {
    let newOrders = { ...this.state.orders };
    let totaleQuantity = newOrders.totalQuantity;
    newOrders.products.map((elem, index) => {
      if (obj.id === index) {
        elem.disappear = true;
        totaleQuantity -= elem.quantity;
      }
      return { elem };
    });
    newOrders.totalQuantity = totaleQuantity;
    this.setState({ orders: newOrders });
  }

  deleteHeaderLine(obj) {
    let newOrders = { ...this.state.orders };
    let newInvTimeAbs = 0;
    let newInvTime = 0;

    newOrders.tableRow.map((elem, index) => {
      if (obj.id === index) {
        newOrders.tableRow.splice(index, 1);
      }
      return elem;
    });

    for (let i = 0; i < newOrders.tableRow.length; i++) {
      if (newOrders.tableRow[i].totalPerOpTime !== 0) {
        newInvTimeAbs += newOrders.tableRow[i].totalPerOpTimeAbs;
      }
    }

    newInvTime = `${Math.floor(newInvTimeAbs / 3600000)} h ${
      (newInvTimeAbs / 60000) % 60
    } min`;

    newOrders.totalInvTime = newInvTime;
    newOrders.totalInvTimeAbs = newInvTimeAbs;

    this.setState({ orders: newOrders });
  }

  duplicateLine(obj) {
    let newOrders = { ...this.state.orders };
    let product = newOrders.products.find(ord => _.isEqual(ord, obj.elem));
    let inventory_id = newOrders.products.length + 1;

    for(let i = 0 ; i < (obj.duplicateNumber) ; i++){
      let newProduct = {...product};
      newProduct.sku = `${this.state.orders.order_id}-${this.partner}-${(inventory_id+i)}`;
      newProduct.imei = "";
      newOrders.products.push(newProduct);
    }

    this.setState({ orders: newOrders });
  }

  updateErrorHasChange(elem){
    let newOrders = { ...this.state.orders };
    let product = newOrders.products.find(ord => _.isEqual(ord, elem));
    product.errorHasChange = false;

    for(let i = 0 ; i < newOrders.products.length ; i++){
      if(_.isEqual(newOrders.products[i], elem)){
        newOrders.products[i].errorHasChange = false;
        break;
      }
    }

    this.setState({orders : newOrders});
  }

  generateProductsLine() {
    let products = this.state.orders.products;

    let mappedProducts = [];

    products
      .map((elem, index) => {
        elem.id = index;
        return elem;
      })
      .filter((elem) => !elem.disappear)
      .map((elem) => {
        mappedProducts.push(
          <LigneInventaire
            id={elem.id}
            elem={elem}
            hasChange={elem.errorHasChange}
            updateErrorChange={this.updateErrorHasChange}
            categories={this.props.categories}
            onChangeCategorie={this.onChangeCategorie}
            onChangeQuantite={this.onChangeQuantite}
            onChangeModel={this.onChangeModel}
            onChangeBrand={this.onChangeBrand}
            onChangeWeight={this.onChangeWeight}
            onChangeFailure={this.onChangeFailure}
            onChangeState={this.onChangeState}
            onChangeScreenSize={this.onChangeScreenSize}
            onChangeRemarks={this.onChangeRemarks}
            onChangeImei={this.onChangeImei}
            deleteLine={this.deleteLine}
            duplicateLine={this.duplicateLine}
          />
        );
      });

    return mappedProducts;
  }

  onClickAddButton(e) {
    let orders = { ...this.state.orders };
    let number = orders.products.length + 1;
    orders.products.push({
      sku: `${this.state.orders.order_id}-${window.sessionStorage.getItem(
        "partner"
      )}-${number}`,
      categorie: "",
      catError: false,
      errorHasChange : false,
      error:{
        brand:false,
        model:false,
        quantity:false,
        remarks:false,
        imei:false,
        failure:false,
        weight:false,
        screenSize:false,
        state:false
      },
      brand: "",
      model: "",
      quantity: "",
      remarks: "",
      imei: "",
      failure: "",
      weight: "",
      screenSize: "",
      state: "Non HS",
      catNumber: "",
    });

    this.setState({ orders: orders });
  }

  handleClickSaveOrder(event) {
    let newOrders = this.state.orders;
    this.props.saveOrder(newOrders);
  }

  onClickSubmit(event) {
    let newOrders = {...this.state.orders};
    let toSubmit = true;
    let modalError = false;
    let numberDisappear = 0;
    let list = [
      {name: "brand", special: function(elem){
        return true;
      }},
      {name: "model", special : function(elem){
        return true;
      }},
      {name:"screenSize", special:function(elem){
        return true;
      }},
      {name:"imei", special:function(elem){
        return true;
      }},
      {name:"quantity", special:function(elem){
        return true;
      }},
      {name:"weight", special:function(elem){
        return true;
      }},
      {name:"failure", special: function(elem){
        return elem.state === "HS";
      }},
      {name:"state", special:function(elem){
        return true;
      }},
      {name:"remarks", special:function(elem){
        return true;
      }},
    ];
    if (newOrders.invResponsable === "") {
      toSubmit = false;
      newOrders.invResponsableError = true;
    }

    newOrders.products.map((elem) => {
      elem.catError = elem.categorie === "";

      if (elem.catError) {
        toSubmit = false;
        return elem;
      } else {
        for (let i = 0; i < list.length; i++) {
          elem.error[list[i].name] =
            elem.gestionRule[list[i].name] === "mandatory" && elem[list[i].name] === "" && list[i].special(elem);
          if (elem.error[list[i].name]) {
            elem.errorHasChange = true;
            toSubmit = false;
          }
        }
      }
      if (elem.disappear === true) {
        numberDisappear += 1;
      }
      
      return elem;
    });
    if (newOrders.products.length === 0 || newOrders.products.length === numberDisappear) {
      toSubmit = false;
      modalError = true;
    }

    this.setState({ orders: newOrders, open: toSubmit, modalError: modalError });
  }

  finalSubmit() {
    let passwordError = false;
    if (this.state.password === password.password_submit) {
      this.props.submitOrder(this.state.orders);
      this.props.navigate("/inventories");
    } else {
      passwordError = true;
      this.setState({ passwordError: passwordError });
    }
  }

  handleChangePassword(obj) {
    this.setState({ password: obj.target.value });
  }

  onClickAddHeaderButton(e) {
    let orders = { ...this.state.orders };
    orders.tableRow.push({
      name: "",
      startTime: "",
      startTimeAbs: 0,
      endTime: "",
      endTimeAbs: 0,
      totalPerOpTime: 0,
      totalPerOpTimeAbs: 0,
    });

    this.setState({ orders: orders });
  }

  componentDidMount() {
    if (!this.props.categories) {
      this.props.getCategories();
    }

    if (
      this.state.orders.products.length === 0 &&
      this.state.orders.name === "" &&
      !!this.props.orderUpdate
    ) {
      let orders = { ...this.state.orders };
      orders.order_id = this.props.orderUpdate.order_id;
      orders.name = this.props.orderUpdate.order_name;
      orders.client_id = this.props.orderUpdate.seller_id;
      orders.products = this.props.orderUpdate.data.products;
      orders.totalInvTime = this.props.orderUpdate.data.totalInvTime;
      orders.totalInvTimeAbs = this.props.orderUpdate.data.totalInvTimeAbs;
      orders.totalWeight = this.props.orderUpdate.data.totalWeight;
      orders.totalQuantity = this.props.orderUpdate.data.totalQuantity;
      orders.invResponsable = this.props.orderUpdate.data.invResponsable;
      orders.tableRow = this.props.orderUpdate.data.header;

      this.setState({ orders: orders });
    } else if (
      this.state.orders.products.length === 0 &&
      this.state.orders.name === "" &&
      !!this.props.createOrder
    ) {
      let orders = { ...this.state.orders };
      orders.order_id = this.props.createOrder.order_id;
      orders.name = this.props.createOrder.order_name;
      orders.client_id = this.props.createOrder.seller_id;

      this.setState({ orders: orders });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Navbarhub />
          <div className="scrollable" style={{ marginTop: "60px" }}>
            <div className="row pb-4">
              <Header_create_inventories
                orders={this.state.orders}
                handleChangeName={this.handleChangeName}
                handleChangeStartTime={this.handleChangeStartTime}
                handleChangeEndTime={this.handleChangeEndTime}
                handleRespName={this.handleRespName}
                tableRow={this.state.tableRow}
                onClickAddHeaderButton={this.onClickAddHeaderButton}
                deleteHeaderLine={this.deleteHeaderLine}
              ></Header_create_inventories>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-container">
                  <TableContainer
                    sx={{
                      height: "40em",

                      width: "95%",
                      margin: "auto",
                      marginBottom: "30px",
                      marginTop: "30px",
                      bottom: "100px",
                    }}
                  >
                    <Table
                      stickyHeader
                      size="small"
                      aria-label="a dense table"
                      sx={{ maxHeight: "90%" }}
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              fontFamily: "Regular",
                              color: "rgba(96, 96, 96)",
                              borderTop: 1,
                              borderBottom: 1,
                              borderColor: "black",
                            },
                          }}
                        >
                          <TableCell>SKU</TableCell>
                          <TableCell>Catégorie</TableCell>
                          <TableCell>Marque</TableCell>
                          <TableCell>Modèle</TableCell>
                          <TableCell>Taille écran</TableCell>
                          <TableCell>S/N - IMEI</TableCell>
                          <TableCell>Quantité</TableCell>
                          <TableCell>Poids&nbsp;(kg)</TableCell>
                          <TableCell>Etat</TableCell>
                          <TableCell>Panne</TableCell>
                          <TableCell>Remarques</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>{this.generateProductsLine()}</TableBody>
                    </Table>

                    <div
                      className="col-12"
                      margin="auto"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "1rem",
                        }}
                        onClick={this.onClickAddButton}
                      >
                        +
                      </Button>{" "}
                    </div>
                    <div
                      className="col-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {" "}
                      {this.state.modalError ? (
                        <Alert
                          severity="error"
                          onClose={() => {
                            this.setState({ modalError: false });
                          }}
                          sx={{ width: "500px" }}
                        >
                          Merci de renseigner au moins une catégorie
                        </Alert>
                      ) : (
                        ""
                      )}
                    </div>
                  </TableContainer>
                </div>
              </div>
            </div>

            <footer className="row">
              <Navbar fixed="bottom" style={{ paddingBottom: "25px" }}>
                <div className="col-12">
                  <div className="row" style={{ justifyContent: "flex-end" }}>
                    <Stack
                      spacing={2}
                      direction="row"
                      elevation={0}
                      width="auto"
                      paddingRight="16px"
                    >
                      <Link to={"/inventories"}>
                        <Button
                          variant="contained"
                          onClick={this.handleClickSaveOrder}
                          size="small"
                          sx={{
                            fontSize: "12px",
                            padding: "8px 8px",
                            width: "auto",
                            height: "auto",
                            fontFamily: "RobotoRegular",
                          }}
                        >
                          Enregistrer en brouillon
                        </Button>
                      </Link>

                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                          fontSize: "12px",
                          padding: "8px 8px",
                          fontFamily: "RobotoRegular",
                        }}
                        onClick={this.onClickSubmit}
                      >
                        Soumettre l'inventaire
                      </Button>

                      <Dialog open={this.state.open} onClose={this.handleClose}>
                        <DialogContent>
                          <DialogContentText>
                            Mot de passe du responsable OBLIGATOIRE :
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            type="password"
                            fullWidth
                            variant="standard"
                            value={this.state.password}
                            onChange={this.handleChangePassword}
                          />
                          {this.state.passwordError ? (
                            <Alert
                              severity="error"
                              onClose={() => {
                                this.setState({ passwordError: false });
                              }}
                              sx={{ width: "500px" }}
                            >
                              Mot de passe incorrect
                            </Alert>
                          ) : (
                            ""
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleClose}>Retour</Button>
                          <Button onClick={this.finalSubmit}>Soumettre</Button>
                        </DialogActions>
                      </Dialog>
                    </Stack>
                  </div>
                </div>
              </Navbar>
            </footer>
          </div>
        
      </div>
    );
  }
}

const CreateInventoryWrapper = (props) => {
  const navigate = useNavigate();

  return <CreateInventory {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    orderUpdate: state.orderUpdate,
    createOrder: state.createOrder,
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  saveOrder,
  submitOrder,
  getCategories,
  getBrands,
  getModels
})(CreateInventoryWrapper);
