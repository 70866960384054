import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLoginInfo, checkAuth } from "../redux/index";
import { Navigate } from "react-router-dom";
import Navbarhub from "../components/navbarhub";
import "../app.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: false,
      emailLogin: "",
      passwordLogin: "",
      errorLogin: "",
      loginInfo: {},
      loginInfoError: "",
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmitSignIn = this.handleSubmitSignIn.bind(this);
    this.resetErrorLogin = this.resetErrorLogin.bind(this);
  }

  componentDidUpdate() {
    if (this.props.auth && !this.state.navigation) {
      this.setState({ navigation: true });
    }

    if (this.props.loginInfo) {
      window.sessionStorage.setItem(
        "hubToken",
        this.props.loginInfo.headers["x-access-token"]
      );
      window.sessionStorage.setItem(
        "email",
        this.props.loginInfo.data.user.email
      );
      window.sessionStorage.setItem(
        "role",
        this.props.loginInfo.data.user.role
      );
      window.sessionStorage.setItem("id", this.props.loginInfo.data.user.id);
      window.sessionStorage.setItem(
        "is_admin",
        this.props.loginInfo.data.user.is_admin
      );
      window.sessionStorage.setItem(
        "partner",
        this.props.loginInfo.data.user.partner
      );
      this.props.checkAuth();
      if (!this.state.navigation) {
        this.setState({ navigation: true });
      }
    } else if (!!this.props.loginInfoError && this.state.errorLogin === "") {
      this.setState({
        errorLogin: "E-mail ou mot de passe incorrect",
        loginInfoError: this.props.loginInfo,
      });
    }
  }

  handleEmailChange(event) {
    this.setState({ emailLogin: event.target.value, loginInfoError: "" });
  }

  handlePasswordChange(event) {
    this.setState({ passwordLogin: event.target.value, loginInfoError: "" });
  }

  handleSubmitSignIn(event) {
    event.preventDefault();
    if (!this.state.emailLogin) {
      this.setState({ errorLogin: "E-mail requis" });
    } else if (!this.state.passwordLogin) {
      this.setState({ errorLogin: "Mot de passe requis" });
    } else {
      let loginInfo = {
        email: this.state.emailLogin,
        password: this.state.passwordLogin,
      };
      this.props.getLoginInfo(loginInfo);
    }
  }

  resetErrorLogin() {
    this.setState({ errorLogin: "" });
    // }
  }

  render() {
    return (
      <div>
        <Navbarhub />
        {this.state.navigation ? (
          <Navigate
            to="/auth/home"
            replace={true}
            state={{ redirect: true }}
          ></Navigate>
        ) : (
          <div className="container">
            <div className="login" style={{ marginTop: "7rem" }}>
              <h2 className="text--h2">Connexion</h2>
              <div className="login__form">
                <Form>
                  <FormGroup>
                    <Label for="exampleEmail" className="font_grey font_light">
                      Adresse e-mail
                    </Label>
                    <Input
                      id="exampleEmail"
                      name="email"
                      type="email"
                      onChange={this.handleEmailChange}
                      onFocus={this.resetErrorLogin}
                      style={
                        this.state.errorLogin !== ""
                          ? { borderColor: "red" }
                          : {
                              backgroundColor: "rgb(244, 244, 244)",
                              border: "none",
                            }
                      }
                    />
                  </FormGroup>
                  <FormGroup style={{ paddingBottom: "20px" }}>
                    <Label
                      for="examplePassword"
                      className="font_grey font_light"
                    >
                      Mot de passe
                    </Label>
                    <Input
                      id="examplePassword"
                      name="password"
                      type="password"
                      onChange={this.handlePasswordChange}
                      onFocus={this.resetErrorLogin}
                      style={
                        this.state.errorLogin !== ""
                          ? { borderColor: "red" }
                          : {
                              backgroundColor: "rgb(244, 244, 244)",
                              border: "none",
                            }
                      }
                    />
                  </FormGroup>
                </Form>
                {this.state.errorLogin && (
                  <p className="error-message">{this.state.errorLogin}</p>
                )}

                <Button
                  onClick={this.handleSubmitSignIn}
                  color="primary"
                  className="btn_color_orange"
                  style={{
                    marginBottom: "15px",
                    border: "none",
                  }}
                >
                  Continuer
                </Button>

                <div
                  className="td-text-center"
                  style={{
                    display: "flex",
                    flexDirection: "raw",
                    justifyContent: "center",
                  }}
                >
                  <Link to={"/forgotten_password"} className="tds-link">
                    <p className="p">Mot de passe oublié ?</p>
                  </Link>
                </div>

                <hr
                  className="login__divider tds-text--500"
                  data-divider-label="ou"
                ></hr>
                <br></br>
                <Link to={"/signup"}>
                  <Button
                    style={{
                      borderWidth: "3px",
                      borderColor: "black",
                      backgroundColor: "#fff",
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                    className="btn_login"
                  >
                    Créer un compte
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginInfo,
    loginInfoError: state.loginInfoError,
    navigate: state.navigate,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getLoginInfo, checkAuth })(Login);
