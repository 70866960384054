import React from "react";
import "../app.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { FormControl, MenuItem, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { connect } from "react-redux";
import {
  getInventoryDetails
} from "../redux/index";

class MainTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelectionModel: [],
      selectedArbitration: {},
      headCells: [
        {
          headerName: "Sku",
          field: "sku",
          numeric: true,
          disablePadding: false,
          label: "SKU",
          width: 110,
          headerAlign: "left",
        },
        {
          headerName: "Catégorie",
          field: "category",
          numeric: false,
          disablePadding: false,
          label: "Catégories",
          width: 160,
          headerAlign: "left",
        },
        {
          headerName: "Marque",
          field: "brand",
          numeric: false,
          disablePadding: false,
          label: "Marque",
          width: 120,
          headerAlign: "left",
        },
        {
          headerName: "Modèle",
          field: "model",
          numeric: false,
          disablePadding: false,
          label: "Modèle",
          width: 160,
          headerAlign: "left",
        },
        {
          headerName: "Etat",
          field: "state",
          numeric: false,
          disablePadding: false,
          label: "Etat",
          width: 110,
          headerAlign: "left",
        },
        {
          headerName: "Arbitrage",
          field: "wanted_option",
          numeric: false,
          disablePadding: false,
          label: "Arbitrage",
          width: 200,
          headerAlign: "center",
        },
        {
          headerName: "Remarques",
          field: "remarks",
          numeric: false,
          disablePadding: false,
          label: "Remarques",
          width: 200,
          headerAlign: "center",
        },
      ],
      numSelected: 0,
    };

    this.handleChangeArbitration = this.handleChangeArbitration.bind(this);
    this.handleRemarks = this.handleRemarks.bind(this);
    this.handleChangeArbitration = this.handleChangeArbitration.bind(this);
  }

  handleRemarks(event, id) {
    let sku = id;
    let remarks = event.target.value;

    this.props.handleRemarks({ remarks, sku });
  }

  handleChangeArbitration(event, sku) {
    const wantedOption = event.target.value;

    if (this.state.rowSelectionModel.length === 0) {
      // Change the value of a single select when no rows are selected
      let updatedArbitration = {
        ...this.state.selectedArbitration,
        [sku]: wantedOption,
      };
      this.props.handleArbitration({ updatedArbitration, sku });
      this.setState({
        selectedArbitration: updatedArbitration,
      });
    } else {
      // Change the value of multiple selects when multiple rows are selected
      const updatedArbitration = { ...this.state.selectedArbitration };

      this.state.rowSelectionModel.forEach((selectedSku) => {
        updatedArbitration[selectedSku] = wantedOption;
      });
      this.props.handleArbitration({ updatedArbitration, sku });
      this.setState({
        selectedArbitration: updatedArbitration,
      });
    }
  }

  radioButon(obj) {
    const value = this.state.selectedArbitration[obj.id] || "";

    return (
      <FormControl sx={{ m: 1, minWidth: 120, width: 140 }} size="small">
        <Select
          id={obj.id}
          value={value}
          onChange={(event) => this.handleChangeArbitration(event, obj.id)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"sell"}>Sell</MenuItem>
          <MenuItem value={"give"}>Give</MenuItem>
          <MenuItem value={"recycle"}>Recycle</MenuItem>
        </Select>
      </FormControl>
    );
  }

  render() {
    return (
      <div>
        <Box sx={{ width: "100%", height: "300px" }}>
          <Paper elevation={2} sx={{ width: "100%", mb: 2, height: "100%" }}>
            <DataGrid
              checkboxSelection
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              rows={this.props.inventoryDetails}
              columns={this.state.headCells.map((column) => ({
                ...column,
                renderCell: (params) => {
                  if (column.field === "wanted_option" && this.props.status === 'to_arbitrate') {
                    return (
                      <div className="d-flex justify-content-center align-items-center">
                        {this.radioButon({ id: params.row.sku })}
                      </div>
                    );
                  } else {
                    console.log(params);
                    return params.value;
                  }
                },
              }))}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                this.setState({ rowSelectionModel: newRowSelectionModel });

                // Update the selectedArbitration value for all selected rows
                const updatedArbitration = {};
                newRowSelectionModel.forEach((selectedSku) => {
                  updatedArbitration[selectedSku] =
                    this.state.selectedArbitration[selectedSku] || "";
                });

                this.setState({
                  selectedArbitration: {
                    ...this.state.selectedArbitration,
                    ...updatedArbitration,
                  },
                });
              }}
              rowSelectionModel={this.state.rowSelectionModel}
              sx={{
                minWidth: 750,
                "& .MuiTablePagination-text": {
                  fontFamily: "Regular",
                },
                "& .MuiTablePagination-selectLabel": {
                  fontFamily: "Regular",
                },
                "& .MuiTablePagination-select": {
                  fontFamily: "Regular",
                },
                "& .MuiTablePagination-displayedRows": {
                  fontFamily: "Regular",
                },
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    "margin-top": "1em",
                    "margin-bottom": "1em",
                  },
                p: 2,
                pb: 0,
              }}
              size={"small"}
              getRowId={(row) => row.sku}
            ></DataGrid>
          </Paper>
        </Box>
      </div>
    );
  }
}


export default MainTable;
