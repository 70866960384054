import React from "react";
import { checkAuth } from "../redux/index";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import logo from "../assets/manutan.png";
import { Navigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { BorderBottom } from "@mui/icons-material";

class Navbarhub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClickDeconnect = this.onClickDeconnect.bind(this);
  }

  onClickDeconnect() {
    window.sessionStorage.clear();
  }

  componentDidMount() {
    this.props.checkAuth();
  }

  render() {
    const styleNav = {
      display: "contents",
    };

    return (
      <div className="pb-3">
        <Navbar
          fixed="top"
          style={{
            backgroundColor: "#F5F5F5",
            borderBottom: "1px solid rgb(235,235,235)",
          }}
        >
          <NavbarBrand href="/">
            <img
              alt="logo"
              src={logo}
              style={{
                height: 40,
                width: "auto",
              }}
            />
          </NavbarBrand>
          <Nav className="me-auto">
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/inventories">Inventaires</NavLink>
            </NavItem>
          </Nav>
          {this.props.auth || this.props.forceAuth ? (
            <Nav className="me-auto" style={styleNav}>
              <NavItem>
                <NavLink href="/login">Mon compte</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/" onClick={this.onClickDeconnect}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#D7493B",
                      border: "none",
                      borderRadius: "20px",
                      fontFamily: "SemiBold",
                      fontStyle: "normal",
                    }}
                  >
                    Se déconnecter
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          ) : (
            <Nav className="me-auto" style={styleNav}>
              <NavItem>
                <NavLink href="/login">Se connecter</NavLink>
              </NavItem>
            </Nav>
          )}
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { checkAuth })(Navbarhub);
