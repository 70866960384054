import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendRegistrationInfo } from "../redux/index";
import "../app.css";
import Navbarhub from "../components/navbarhub";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSignUp: "",
      passwordCreation: "",
      passwordConfirmation: "",
      errorSignUp: "",
      isSignIn: true,
      name: "",
      firstname: "",
      role: "",
    };

    this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
    this.isPasswordValid = this.isPasswordValid.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordCreationChange =
      this.handlePasswordCreationChange.bind(this);
    this.handlePasswordConfirmationChange =
      this.handlePasswordConfirmationChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ emailSignUp: event.target.value });
  }

  handlePasswordCreationChange(event) {
    this.setState({ passwordCreation: event.target.value });
  }

  handlePasswordConfirmationChange(event) {
    this.setState({ passwordConfirmation: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleFirstNameChange(event) {
    this.setState({ firstname: event.target.value });
  }

  handleRoleChange(event) {
    this.setState({ role: event.target.value });
  }

  isEmailValid(email) {
    const hasManutan = /.+@.+\.[gmail.com]/;
    return hasManutan.test(email);
  }

  isPasswordValid(password) {
    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*)(+=._-]/;
    const hasHeight = /(?=.{8,})/;
    const hasMaj = /[A-Z]/;

    return (
      hasNumber.test(password) &&
      hasSymbol.test(password) &&
      hasHeight.test(password) &&
      hasMaj.test(password)
    );
  }

  handleSubmitSignUp(event) {
    event.preventDefault();
    if (!this.state.name) {
      this.setState({ errorSignUp: "Nom requis" });
    } else if (!this.state.firstname) {
      this.setState({ errorSignUp: "Prénom requis" });
    } else if (!this.state.emailSignUp) {
      this.setState({ errorSignUp: "E-mail requis" });
    } else if (this.isEmailValid(this.state.emailSignUp) !== true) {
      this.setState({ errorSignUp: "E-mail incorrect" });
    } else if (!this.state.passwordCreation) {
      this.setState({ errorSignUp: "Mot de passe requis" });
    } else if (!this.state.passwordConfirmation) {
      this.setState({ errorSignUp: "Mot de passe requis" });
    } else if (
      this.state.passwordConfirmation !== this.state.passwordCreation
    ) {
      this.setState({ errorSignUp: "Les mots de passe ne sont pas les mêmes" });
    } else if (this.isPasswordValid(this.state.passwordCreation) !== true) {
      this.setState({
        errorSignUp:
          "Le mot de passe doit contenir au moins 8 caractères dont une majuscule, un symbole et un chiffre",
      });
    } else if (!this.state.role) {
      this.setState({ errorSignUp: "Choisir un rôle" });
    } else {
      let registrationInfo = {
        email: this.state.emailSignUp,
        password: this.state.passwordCreation,
        name: this.state.name,
        firstname: this.state.firstname,
      };
      this.props.sendRegistrationInfo(registrationInfo);
      if (!!this.props.sendRegistrationError && this.state.errorSignUp === "") {
        this.setState({
          errorSignUp: this.props.sendRegistrationError,
        });
      }
      if (!this.props.sendRegistrationError) {
        this.props.navigate("/signupConfirmation");
      }
    }
  }

  render() {
    return (
      <div>
        <Navbarhub />
        <div className="container">
          <div className="login" style={{ marginTop: "6rem" }}>
            <h2 className="text--h2">Créer un compte</h2>
            <div className="login__form" style={{ marginTop: "0" }}>
              <Form>
                <FormGroup>
                  <Label for="exampleEmail" className="font_grey font_light">
                    Nom
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={this.handleNameChange}
                    style={{
                      backgroundColor: "rgb(244, 244, 244)",
                      border: "none",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword" className="font_grey font_light">
                    Prénom
                  </Label>
                  <Input
                    id="firstname"
                    name="firstname"
                    type="text"
                    onChange={this.handleFirstNameChange}
                    style={{
                      backgroundColor: "rgb(244, 244, 244)",
                      border: "none",
                      marginBottom: "0",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword" className="font_grey font_light">
                    E-mail
                  </Label>
                  <Input
                    id="Email"
                    name="email"
                    type="email"
                    onChange={this.handleEmailChange}
                    style={{
                      backgroundColor: "rgb(244, 244, 244)",
                      border: "none",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword" className="font_grey font_light">
                    Mot de passe
                  </Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    onChange={this.handlePasswordCreationChange}
                    style={{
                      backgroundColor: "rgb(244, 244, 244)",
                      border: "none",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword" className="font_grey font_light">
                    Confirmez votre mot de passe
                  </Label>
                  <Input
                    id="confirmationPassword"
                    name="passwordConfirmation"
                    type="password"
                    onChange={this.handlePasswordConfirmationChange}
                    style={{
                      backgroundColor: "rgb(244, 244, 244)",
                      border: "none",
                    }}
                  />
                </FormGroup>
              </Form>

              <FormGroup className="form-group">
                <Label for="backdrop">Rôle</Label>{" "}
                <Input
                  type="select"
                  name="backdrop"
                  id="backdrop"
                  onChange={this.handleRoleChange}
                  value={this.state.role}
                  style={{
                    borderWidth: "3px",
                    borderColor: "black",
                    backgroundColor: "#fff",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                  className="btn_login"
                >
                  <option value="None">-</option>
                  <option value="Admin">Admin</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Operateur">Opérateur</option>
                </Input>
              </FormGroup>
              {this.state.errorSignUp && (
                <p className="error-message">{this.state.errorSignUp}</p>
              )}
              <Link to={"/login"} onClick={this.handleSubmitSignUp}>
                <Button
                  className="btn color_blue font_light"
                  style={{
                    marginBottom: "15px",
                    backgroundColor: "rgb(14, 112, 244)",
                    border: "none",
                  }}
                >
                  Créer un compte
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sendRegistrationInfo: state.sendRegistrationInfo,
    sendRegistrationError: state.sendRegistrationError,
    navigate: state.navigate,
  };
};

export default connect(mapStateToProps, {
  sendRegistrationInfo,
})(Signup);
