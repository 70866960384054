import ApiService from "../services/apiService";

import {
  CHECK_AUTH,
  GET_LOGIN_INFO,
  POST_REGISTRATION_INFO,
  GET_INVENTORY_DETAILS,
  EXPORT_PDF,
  GET_LOGIN_ERROR,
  GET_ORDERS,
  GET_ORDER_DETAILS,
  SAVE_ORDER,
  UPDATE_ORDER,
  CREATE_ORDER,
  SUBMIT_ORDER,
  GET_CATEGORIES,
  GET_BRAND,
  GET_MODEL,
  GET_EMAIL_INFO,
  GET_EMAIL_ERROR,
  SEND_REGISTRATION_ERROR,
  PUT_PASSWORD_RESET,
} from "./types";

export function submitOrder(order) {
  return async function (dispatch) {
    let obj = { ...order, id: window.sessionStorage.getItem("id") };
    const res = await ApiService.submitOrder(obj);
    dispatch({
      type: SUBMIT_ORDER,
      payload: res.data,
    });
  };
}

export function updateOrder(order) {
  return {
    type: UPDATE_ORDER,
    payload: order,
  };
}

export function saveOrder(inventory) {
  return async function (dispatch) {
    let obj = { ...inventory, id: window.sessionStorage.getItem("id") };
    const res = await ApiService.postSaveOrder(obj);
    dispatch({
      type: SAVE_ORDER,
      payload: res.data,
    });
  };
}

export function checkAuth() {
  return async function (dispatch) {
    try {
      const res = await ApiService.getCheckAuth();
      dispatch({
        type: CHECK_AUTH,
        payload: true,
      });
    } catch (error) {
      dispatch({
        type: CHECK_AUTH,
        payload: false,
      });
    }
  };
}

export function getLoginInfo(loginInfo) {
  return async function (dispatch) {
    try {
      const response = await ApiService.getLoginInfo(loginInfo);
      dispatch({
        type: GET_LOGIN_INFO,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_LOGIN_ERROR,
        error: error.message,
      });
    }
  };
}

export function getEmailInfo(emailInfo) {
  return async function (dispatch) {
    try {
      const response = await ApiService.getEmailInfo(emailInfo);
      dispatch({
        type: GET_EMAIL_INFO,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_EMAIL_ERROR,
        error: error.message,
      });
    }
  };
}

export function sendRegistrationInfo(registrationInfo) {
  return async function (dispatch) {
    try {
      const response = await ApiService.sendRegistrationInfo(registrationInfo);
      dispatch({ type: POST_REGISTRATION_INFO, payload: response.data });
    } catch (error) {
      dispatch({
        type: SEND_REGISTRATION_ERROR,
        error: error.message,
      });
    }
  };
}

export function getInventoryDetails(orderId) {
  return async function (dispatch) {
    const response = await ApiService.getInventoryDetails(orderId);
    dispatch({ type: GET_INVENTORY_DETAILS, payload: response.data });
  };
}

export function exportPdf(orderId) {
  return async function (dispatch) {
    const response = await ApiService.exportPdf(orderId);
    dispatch({ type: EXPORT_PDF, payload: response.data });
  };
}

export function getOrders(last_id) {
  return async function (dispatch) {
    const response = await ApiService.getOrders(last_id);
    dispatch({ type: GET_ORDERS, payload: response.data });
  };
}

export function getOrderDetails(order_id) {
  return async function (dispatch) {
    const response = await ApiService.getOrderDetails(order_id);
    dispatch({ type: GET_ORDER_DETAILS, payload: response.data });
  };
}

export function createOrder(order_info) {
  return {
    type: CREATE_ORDER,
    payload: order_info,
  };
}

export function getCategories() {
  return async function (dispatch) {
    const response = await ApiService.getCategories();
    dispatch({ type: GET_CATEGORIES, payload: response.data.categories });
  };
}

export function getBrands(brands) {
  return {
    type: GET_BRAND, 
    payload: brands
  };
}

export function getModels(models) {
  return {
    type: GET_MODEL, 
    payload: models
  };
}

export function putPasswordReset(password) {
  return async function (dispatch) {
    try {
      const response = await ApiService.putPasswordReset(password);
      dispatch({
        type: PUT_PASSWORD_RESET,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: PUT_PASSWORD_RESET,
        error: error.message,
      });
    }
  };
}
