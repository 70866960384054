import React from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import {
  TextField,
  Divider,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";

import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScaleIcon from "@mui/icons-material/Scale";
import LigneHeader from "./ligne_tableau_header";

class header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.generateTableLines = this.generateTableLines.bind(this);
    this.handleRespName = this.handleRespName.bind(this);
    this.onClickAddHeaderButton = this.onClickAddHeaderButton.bind(this);
    this.deleteHeaderLine = this.deleteHeaderLine.bind(this);
  }

  generateTableLines() {
    let tableRow = this.props.orders.tableRow;
    let mappedTableRow = [];

    tableRow.map((elem, index) => {
      mappedTableRow.push(
        <LigneHeader
          id={index}
          elem={elem}
          handleChangeName={this.handleChangeName}
          handleChangeStartTime={this.handleChangeStartTime}
          handleChangeEndTime={this.handleChangeEndTime}
          deleteHeaderLine={this.deleteHeaderLine}
        ></LigneHeader>
      );
    });

    return mappedTableRow;
  }

  handleChangeName(obj) {
    this.props.handleChangeName(obj);
  }
  handleChangeStartTime(obj) {
    this.props.handleChangeStartTime(obj);
  }
  handleChangeEndTime(obj) {
    this.props.handleChangeEndTime(obj);
  }
  handleRespName(e) {
    let respName = e.target.value;

    this.props.handleRespName({
      invResponsable: respName,
    });
  }

  onClickAddHeaderButton(e) {
    this.props.onClickAddHeaderButton(e);
  }

  deleteHeaderLine(obj) {
    this.props.deleteHeaderLine(obj);
  }

  render() {
    return (
      <div>
        <div className="container">
          <h2
            className="text--h2"
            style={{ marginTop: "15px", marginBottom: "30px" }}
          >
            Création d'un inventaire
          </h2>
        </div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                width: "fit-content",
                fontFamily: "RobotoRegular",
                "& svg": {
                  m: 1,
                },
                "& hr": {
                  mx: 1.5,
                },
              }}
            >
              <div className="col-5">
                <Box
                  component="form"
                  sx={{
                    justifyContent: "center",
                    "& .MuiTextField-root": {
                      m: 1,
                      width: "30ch",
                    },
                    "& .MuiTextField-label": {},
                    "& .MuiTypography-root": {
                      fontFamily: "Regular",
                      fontSize: "16px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id={"orderId"}
                    disabled
                    label="Order Id"
                    value={this.props.orders.order_id}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: 100,
                      "& input": {
                        height: 20,
                        fontSize: "13px",
                      },
                      "& label": {
                        fontFamily: "Regular",
                      },
                    }}
                  />
                  <TextField
                    disabled
                    key="4"
                    id="clientId"
                    label="Id Client"
                    value={this.props.orders.client_id}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: 100,
                      "& input": {
                        height: 20,
                        fontSize: "13px",
                      },
                      "& label": {
                        fontFamily: "Regular",
                      },
                    }}
                    size="small"
                  />
                  <TextField
                    key="5"
                    disabled
                    id="totalQuantity"
                    label="Quantité totale"
                    value={this.props.orders.totalQuantity}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: 100,
                      "& input": {
                        height: 20,
                        fontSize: "13px",
                      },
                      "& label": {
                        fontFamily: "Regular",
                      },
                    }}
                    size="small"
                  />
                  <TextField
                    key="6"
                    disabled
                    id="totalWeight"
                    label="Poids total (kg)"
                    value={this.props.orders.totalWeight}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: 100,
                      "& input": {
                        height: 20,
                        fontSize: "13px",
                      },
                      "& label": {
                        fontFamily: "Regular",
                      },
                    }}
                    size="small"
                  />
                  <TextField
                    error={
                      this.props.orders.invResponsable === ""
                        ? this.props.orders.invResponsableError
                        : false
                    }
                    key="2"
                    id="invResponsable"
                    label="Responsable"
                    autoFocus="true"
                    onChange={this.handleRespName}
                    value={this.props.orders.invResponsable}
                    sx={{
                      width: 100,
                      "& input": {
                        height: 20,
                        fontSize: "13px",
                      },
                      "& label": {
                        fontFamily: "Regular",
                      },
                    }}
                    size="small"
                  />
                </Box>
              </div>
              <Divider orientation="vertical" flexItem />

              <div className="col-7">
                <TableContainer
                  sx={{
                    height: 120,
                    width: "95%",
                    margin: "auto",
                  }}
                >
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                    sx={{ margin: "auto" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            fontFamily: "Regular",
                            color: "rgba(96, 96, 96)",
                            borderTop: 1,
                            borderColor: "rgb(196,196,196)",
                          },
                        }}
                      >
                        <TableCell>Nom Opérateur</TableCell>
                        <TableCell>Heure début</TableCell>
                        <TableCell>Heure fin</TableCell>
                        <TableCell>Temps passé</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{this.generateTableLines()}</TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  sx={{
                    width: "95%",
                    margin: "auto",
                  }}
                >
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                    sx={{ margin: "auto" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            fontFamily: "Regular",
                            color: "rgba(96, 96, 96)",
                            border: "none",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            height: 10,
                            width: "90px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                          }}
                        >
                          <Button
                            onClick={this.onClickAddHeaderButton}
                            variant="outlined"
                            sx={{
                              color: "rgb(117,117,117)",
                              fontFamily: "Regular",
                              height: "20px",
                              width: "auto",
                              fontSize: "13px",
                              borderColor: "rgb(117,117,117)",
                            }}
                          >
                            Ajouter une ligne
                          </Button>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            height: 10,
                            width: "60px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                          }}
                        >
                          Temps total :
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ height: 10, width: "80px" }}
                        >
                          {this.props.orders.totalInvTime}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    orderInfo: state.order_info,
  };
}

export default connect(mapStateToProps, null)(header);
