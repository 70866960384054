import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { getEmailInfo, checkAuth } from "../redux/index";
import Navbarhub from "../components/navbarhub";
import { useNavigate } from "react-router-dom";
import "../app.css";

class PasswordForgotten extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: false,
      emailPasswordReset: "",
      emailInfoError: "",
      error: "",
      errorMessage: "",
      info: {},
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);
    this.resetError = this.resetError.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
  }

  componentDidUpdate() {}

  handleEmailChange(event) {
    this.setState({
      emailPasswordReset: event.target.value,
      infoError: "",
    });
  }

  isEmailValid(email) {
    const hasManutan = /.+@./;
    return hasManutan.test(email);
  }

  handleSubmitResetPassword(event) {
    event.preventDefault();
    if (!this.state.emailPasswordReset) {
      this.setState({ error: "E-mail requis" });
    } else if (this.isEmailValid(this.state.emailPasswordReset) !== true) {
      this.setState({ error: "E-mail incorrect" });
    } else {
      let emailInfo = {
        email: this.state.emailPasswordReset,
      };
      this.props.getEmailInfo(emailInfo);
      if (!!this.props.emailInfoError && this.state.error === "") {
        this.setState({
          emailInfoError: this.props.emailInfoError,
        });
      }
      if (!this.props.emailInfoError) {
        this.props.navigate("/resetConfirmation");
      }
    }
  }

  resetError() {
    this.setState({ error: "", emailInfoError: "" });
  }

  render() {
    return (
      <div>
        <Navbarhub />
        <div className="container">
          <div className="login" style={{ marginTop: "7rem" }}>
            <h2 className="text--h2">Réinitialisation du mot de passe</h2>
            <div className="login__form">
              <Form>
                <FormGroup>
                  <Label for="exampleEmail" className="font_grey font_light">
                    Adresse e-mail
                  </Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    type="email"
                    onChange={this.handleEmailChange}
                    onFocus={this.resetError}
                    style={
                      this.state.error !== ""
                        ? { borderColor: "red" }
                        : {
                            backgroundColor: "rgb(244, 244, 244)",
                            border: "none",
                          }
                    }
                  />
                </FormGroup>
              </Form>
              {this.state.error && (
                <p className="error-message">{this.state.error}</p>
              )}
              {this.state.emailInfoError && (
                <p className="error-message">{this.state.emailInfoError}</p>
              )}

              <Button
                onClick={this.handleSubmitResetPassword}
                color="primary"
                className="btn_color_orange"
                style={{
                  marginBottom: "15px",
                  border: "none",
                }}
              >
                Continuer
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Password_reset_Wrapper = (props) => {
  const navigate = useNavigate();

  return <PasswordForgotten {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => {
  return {
    emailInfo: state.emailInfo,
    emailInfoError: state.emailInfoError,
    navigate: state.navigate,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getEmailInfo, checkAuth })(
  Password_reset_Wrapper
);
