import React from "react";
import {
  TextField,
  IconButton,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { connect } from "react-redux";
import { getBrands, getModels } from "../redux";
import { FlashOnRounded, ThirtyFpsRounded } from "@mui/icons-material";

class LigneInventaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      duplicateNumber: 0,
      etat: [{ label: "HS" }, { label: "Non HS" }],
      state:"Non HS",
      category:undefined,
      brand:undefined,
      model:undefined,
      imei:undefined,
      quantity:undefined,
      weight:undefined,
      screenSize:undefined,
      errors:{
        brand:false,
        model:false,
        quantity:false,
        remarks:false,
        imei:false,
        failure:false,
        weight:false,
        screenSize:false,
        state:false
      },
      tailleEcran: [
        { label: `15"` },
        { label: `17"` },
        { label: `20"` },
        { label: `22"` },
        { label: `24"` },
        { label: `27"` },
      ],
    };

    this.onChangeCategorie = this.onChangeCategorie.bind(this);
    this.onChangeQuantite = this.onChangeQuantite.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeScreenSize = this.onChangeScreenSize.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeFailure = this.onChangeFailure.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
    this.onChangeImei = this.onChangeImei.bind(this);
    this.deleteLine = this.deleteLine.bind(this);
    this.duplicateLine = this.duplicateLine.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeDuplicateNum = this.handleChangeDuplicateNum.bind(this);
    this.updateError = this.updateError.bind(this);
  }

  onChangeCategorie(e) {
    let categorieName = e.target.textContent;
    let id = this.props.id;
    let group = "";
    let gestionRulePerCat = {};
    let categorie = null;
    this.props.categories.forEach((elem) => {
      if (elem.category.display_name === categorieName) {
        let gestionRule = {
          brand: elem.brand_field_type,
          model: elem.model_field_type,
          imei: elem.serial_number_field_type,
          quantity: elem.quantity_field_type,
          weight: elem.weight_field_type,
          state: elem.state_field_type,
          failure: elem.problem_field_type,
          screenSize: elem.screen_size_field_type,
          remarks: elem.remarks_field_type,
        };
        gestionRulePerCat = gestionRule;
        group = elem.group;
        categorie = { ...elem };
      }
    });

    this.props.onChangeCategorie({
      id: id,
      categorie: categorie,
      gestionRule: gestionRulePerCat,
      catNumber: group,
    });

    this.setState({category: categorie.category})

  }

  onChangeQuantite(e) {
    let quantity = e.target.value;
    let id = this.props.id;

    this.props.onChangeQuantite({
      id: id,
      quantity: quantity,
    });

    this.setState({quantity : quantity});
  }

  onChangeBrand(e) {
    let brand = e.target.textContent;
    let id = this.props.id;
    let brand_obj = {};

    for (let i = 0; i < this.props.brands.length; i++) {
      if (brand === this.props.brands[i].brand.brand) {
        brand_obj = this.props.brands[i];
        break;
      }
    }

    this.props.onChangeBrand({
      id: id,
      brand: brand_obj,
    });

    this.setState({brand : brand_obj.brand})
  }

  onChangeModel(e) {
    let model = e.target.textContent;
    let id = this.props.id;
    let model_obj = {};

    for (let i = 0; i < this.props.models.length; i++) {
      if (model === this.props.models[i].model) {
        model_obj = this.props.models[i];
        break;
      }
    }

    this.props.onChangeModel({
      id: id,
      model: model_obj,
    });

    this.setState({model: model_obj.model});
  }

  onChangeWeight(e) {
    let weight = e.target.value;
    let id = this.props.id;

    this.props.onChangeWeight({
      id: id,
      weight: weight,
    });

    this.setState({weight : weight});
  }

  onChangeScreenSize(e) {
    let screenSize = e.target.textContent;
    let id = this.props.id;

    this.props.onChangeScreenSize({
      id: id,
      screenSize: screenSize,
    });
  }

  onChangeState(e) {
    let state = e.target.value;
    let id = this.props.id;

    this.props.onChangeState({
      id: id,
      state: state,
    });

    this.setState({state : state});
  }

  onChangeFailure(e) {
    let failure = e.target.textContent;
    let id = this.props.id;

    this.props.onChangeFailure({
      id: id,
      failure: failure,
    });
  }

  onChangeRemarks(e) {
    let remarks = e.target.value;
    let id = this.props.id;

    this.props.onChangeRemarks({
      id: id,
      remarks: remarks,
    });
  }

  onChangeImei(e) {
    let imei = e.target.value;
    let id = this.props.id;

    this.props.onChangeImei({
      id: id,
      imei: imei,
    });

    this.setState({imei : imei});
  }

  deleteLine(e) {
    let id = this.props.id;
    this.props.deleteLine({ id: id });
  }

  duplicateLine(e) {
    let number = this.state.duplicateNumber;
    let id = this.props.id;
    let elem = this.props.elem;
    this.props.duplicateLine({ id: id, elem: elem, duplicateNumber: number });
    this.setState({ open: false });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleChangeDuplicateNum(obj) {
    this.setState({ duplicateNumber: obj.target.valueAsNumber });
  }

  componentDidUpdate() {
    if (this.props.elem.categorie.display_name && !this.props.brands) {
      this.props.categories.forEach((cat)=>{
        if(cat.category.display_name === this.props.elem.categorie.display_name){
          this.props.getBrands(cat.brands);
        }
      })
    }

    if (this.props.elem.brand.brand && !this.props.models && this.props.brands) {
      this.props.brands.forEach((brand)=> {
        if(this.props.elem.brand.brand === brand.brand.brand){
          this.props.getModels(brand.devices);
        }
      })
    }
  }

  updateError(){
    let errors = {...this.state.errors};
    errors = this.props.elem.error;

    this.props.updateErrorChange(this.props.elem);
    this.setState({errors : errors});
  }

  render() {
    return (
      <TableRow
        key={this.props.id}
        sx={{
          "& td": {
            fontFamily: "Regular",
            padding: "5px",
          },
        }}
      >
        
        <TableCell padding="normal">{this.props.elem.sku}</TableCell>
        {this.props.elem.errorHasChange ? this.updateError : ""}
        <TableCell>
          <Autocomplete
            disablePortal
            size="small"
            id="categories"
            disableClearable
            value={this.state.category ? this.state.category.display_name : this.props.elem.categorie ? this.props.elem.categorie.display_name : ""}
            options={this.props.categories ? this.props.categories.map((option) => option.category.display_name) : []}
            onChange={this.onChangeCategorie}
            sx={{
              width: 140,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus="true"
                error={
                  this.props.elem.categorie === ""
                    ? this.props.elem.catError
                    : false
                }
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.brand === "other"
            }
            value={this.state.brand ? this.state.brand.brand : this.props.elem.brand ? this.props.elem.brand.brand : ""}
            disablePortal
            size="small"
            id="marque"
            options={this.props.brands ? this.props.brands.map(brand=>brand.brand.brand) : []}
            onChange={this.onChangeBrand}
            sx={{
              width: 110,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: `${
                        this.props.elem.gestionRule &&
                        this.props.elem.gestionRule.brand === "mandatory" &&
                        !this.props.elem.brand
                        ? "green"
                        : ""
                        }`,
                    },
                  },
                }}
                error={this.state.errors.brand}
                InputProps={{
                  ...params.InputProps,
                  type: "search"
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.model === 'other'
            }
            disablePortal
            value={this.state.model ? this.state.model : this.props.elem.model ? this.props.elem.model.model : ""}
            size="small"
            id="model"
            disableClearable
            options={this.props.models ? this.props.models.map(option => option.model) : []}
            onChange={this.onChangeModel}
            sx={{
              width: 110,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: `${
                        this.props.elem.gestionRule &&
                        this.props.elem.gestionRule.model === "mandatory" &&
                        !this.props.elem.model
                        ? "green"
                        : ""
                        }`,
                    },
                  },
                }}
                error={
                  this.state.errors.model
                }
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.brand === "other"
            }
            disablePortal
            value={this.props.elem.screenSize ? this.props.elem.screenSize : ""}
            size="small"
            id="taille ecran"
            disableClearable
            options={this.state.tailleEcran.map((option) => option)}
            onChange={this.onChangeScreenSize}
            sx={{
              width: "auto",
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: `${
                        this.props.elem.gestionRule &&
                        this.props.elem.gestionRule.screenSize === "mandatory" &&
                        !this.props.elem.screenSize
                        ? "green"
                        : ""
                        }`,
                    },
                  },
                }}
                {...params}
                error={this.state.errors.screenSize}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <TextField
            sx={{
              width: 100,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${
                    this.props.elem.gestionRule &&
                    this.props.elem.gestionRule.imei === "mandatory" &&
                    !this.props.elem.imei
                    ? "green"
                    : ""
                    }`,
                },
              },
            }}
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.imei === "other"
            }
            error={this.state.errors.imei}
            id="imei"
            size="small"
            value={this.state.imei ? this.state.imei : this.props.elem.imei ? this.props.elem.imei : ""}
            type="text"
            variant="outlined"
            onChange={this.onChangeImei}
          />
        </TableCell>
        <TableCell>
          <TextField
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.quantity === "other"
            }
            error={this.state.errors.quantity}
            id="quantite"
            value={this.state.quantity ? this.state.quantity : this.props.elem.quantity ? this.props.elem.quantity : ""}
            size="small"
            type="number"
            variant="outlined"
            onChange={this.onChangeQuantite}
            InputProps={{
              inputProps: {
                min: 0,
                style: {
                  fontSize: "13px",
                },
              },
            }}
            sx={{
              width: 80,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${
                    this.props.elem.gestionRule &&
                    this.props.elem.gestionRule.quantity === "mandatory" &&
                    !this.props.elem.quantity
                    ? "green"
                    : ""
                    }`,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.weight === "other"
            }
            error={this.state.errors.weight}
            id="poids"
            size="small"
            value={this.state.weight ? this.state.weight : this.props.elem.weight ? this.props.elem.weight : ""}
            type="number"
            variant="outlined"
            onChange={this.onChangeWeight}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            sx={{
              width: 80,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiOutlinedInput": {
                fontSize: "13px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${
                    this.props.elem.gestionRule &&
                    this.props.elem.gestionRule.weight === "mandatory" &&
                    !this.props.elem.weight
                    ? "green"
                    : ""
                    }`,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Non HS"
              size="small"
              name="radio-buttons-group"
              sx={{
                width: 140,
                "& input": {
                  height: 10,
                  fontSize: "13px",
                },
              }}
            >
              <FormControlLabel
                disabled={
                  this.props.elem.gestionRule &&
                  this.props.elem.gestionRule.state === "other"
                }
                error={this.state.errors.state}
                value="HS"
                control={<Radio />}
                onChange={this.onChangeState}
                checked={this.state.state === "HS"}
                label="HS"
                sx={{
                  "& svg": {
                    width: "0.5em",
                    height: "0.5em",
                  },
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px",
                  },
                }}
              />
              <FormControlLabel
                disabled={
                  this.props.elem.gestionRule &&
                  this.props.elem.gestionRule.state === "other"
                }
                error={this.state.errors.state}
                value="Non HS"
                control={<Radio />}
                label="Non HS"
                onChange={this.onChangeState}
                checked={this.state.state === "Non HS"}
                sx={{
                  "& svg": {
                    width: "0.5em",
                    height: "0.5em",
                  },
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </TableCell>
        <TableCell>
          <Autocomplete
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.failure === "other"
            }
            disablePortal
            size="small"
            id="panne"
            disableClearable
            options={!!this.props.elem.categorie && !!this.props.elem.categorie.problems_field_list ? this.props.elem.categorie.problems_field_list.map((option) => option) : ""}
            onChange={this.onChangeFailure}
            sx={{
              width: 100,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: `${this.props.elem.gestionRule &&
                        this.props.elem.gestionRule.failure === 'mandatory' &&
                        !this.props.elem.failure &&
                        this.props.elem.state !== "Non HS"
                        ? "green"
                        : ""
                        }`,
                    },
                  },
                }}
                {...params}
                error={this.state.errors.failure}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <TextField
            disabled={
              this.props.elem.gestionRule &&
              this.props.elem.gestionRule.remarks === "other"
            }
            error={this.state.errors.remarks}
            id="remarques"
            size="small"
            value={this.props.elem.remarks ? this.props.elem.remarks : ""}
            type="text"
            variant="outlined"
            onChange={this.onChangeRemarks}
            sx={{
              width: 120,
              "& input": {
                height: 10,
                fontSize: "13px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${this.props.elem.gestionRule &&
                    this.props.elem.gestionRule.remarks === 'mandatory' &&
                    !this.props.elem.remarks
                    ? "green"
                    : ""
                    }`,
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ padding: 0 }}
            onMouseOver={(event) =>
              (event.currentTarget.style.backgroundColor = "#F8FBFF")
            }
            onMouseLeave={(event) =>
              (event.currentTarget.style.backgroundColor = "")
            }
            onClick={this.deleteLine}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ padding: 0 }}
            onMouseOver={(event) =>
              (event.currentTarget.style.backgroundColor = "#F8FBFF")
            }
            onMouseLeave={(event) =>
              (event.currentTarget.style.backgroundColor = "")
            }
          >
            <QrCodeScannerIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={this.handleClickOpen}
            sx={{ padding: 0 }}
            onMouseOver={(event) =>
              (event.currentTarget.style.backgroundColor = "#F8FBFF")
            }
            onMouseLeave={(event) =>
              (event.currentTarget.style.backgroundColor = "")
            }
          >
            <ContentCopyIcon />
          </IconButton>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogContent>
              <DialogContentText>
                Combien de fois souhaitez-vous dupliquer cette ligne ?
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="number"
                fullWidth
                variant="standard"
                onChange={this.handleChangeDuplicateNum}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.duplicateLine}>Dupliquer</Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.brands,
    models: state.models
  };
};

export default connect(mapStateToProps, { getBrands, getModels })(LigneInventaire);
